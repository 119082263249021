import CarouselPlaceholder from "../../../core/ui/assets/carousel-placeholder.png";

import {Button} from "@hipo/react-ui-toolkit";
import classNames from "classnames";
import {generatePath, Link} from "react-router-dom";

import Carousel from "../../../components/carousel/Carousel";
import ListingPropertyCardEquityList from "./equity/list/ListingPropertyCardEquityList";
import ListingPropertyCardMetaInfoList from "./meta-info/list/ListingPropertyCardMetaInfoList";
import ListingPropertyCardHeader from "./header/ListingPropertyCardHeader";
import ListingPropertyCardFooter from "./footer/ListingPropertyCardFooter";
import {generatePrismUrl} from "../../../core/util/image/prism/prismUtils";
import Badge from "../../../components/badge/Badge";
import {PropertyListItem, Property} from "../../api/listingApiModels";
import ListingPropertyCardActionList from "./actions/ListingPropertyCardActionList";
import ListingPropertyCardSkeleton from "./skeleton/ListingPropertyCardSkeleton";
import ROUTES from "../../../core/route/routes";
// SCSS import moved here to override badge styles
import "./_listing-property-card.scss";
import UpfrontPremiumInfo from "./meta-info/UpfrontPremiumInfo";
import EquityDividendInfo from "./meta-info/EquityDividendInfo";
import EquitySummaryInfo from "./meta-info/EquitySummaryInfo";
import listingApi from "../../api/listingApi";

export type ListingPropertyCardView =
  | "small"
  | "large"
  | "detail"
  | "new-home-owners"
  | "action";
export interface ListingPropertyCardEditProps {
  shouldDisplayEditButton: boolean;
  onEditClick: VoidFunction;
}

interface ListingPropertyCardProps {
  property: Property;
  isLoading?: boolean;
  view?: ListingPropertyCardView;
  customClassName?: string;
  shouldDisplayPublishButton?: boolean;
  shouldDisplayCarouselControls?: boolean;
  shouldDisplayShare?: boolean;
  shouldDisplayBookmark?: boolean;
  shouldDisplayEquityList?: boolean;
  editProps?: ListingPropertyCardEditProps;
  listingProps?: Omit<PropertyListItem, "property">;
  isPropertyDetailPage?: boolean;
  shouldHideSoldAndListedEquityLabel?: boolean;
  shouldHideAvailableEquityLabel?: boolean;
  shouldHideOwnedEquityLabel?: boolean;
}

// eslint-disable-next-line
function ListingPropertyCard({
  property,
  view = "large",
  customClassName,
  shouldDisplayPublishButton = true,
  shouldDisplayCarouselControls = true,
  shouldDisplayShare = false,
  shouldDisplayBookmark = false,
  isLoading,
  listingProps,
  editProps,
  isPropertyDetailPage,
  shouldHideAvailableEquityLabel,
  shouldHideOwnedEquityLabel,
  shouldHideSoldAndListedEquityLabel,
  shouldDisplayEquityList = true
}: ListingPropertyCardProps) {
  return isLoading ? (
    <ListingPropertyCardSkeleton />
  ) : (
    <div
      className={classNames(
        "listing-property-card",
        `listing-property-card--view--${view}`,
        {
          "property-detail-property-card": isPropertyDetailPage
        },
        customClassName
      )}
    >
      {view !== "detail" && (
        <div className={"listing-property-card__carousel-wrapper"}>
          <Carousel
            size={view === "large" ? "medium" : "small"}
            customClassName={"listing-property-card__carousel"}
            ariaLabel={"Property images"}
            images={generateCarouselImages()}
            shouldDisplayControls={shouldDisplayCarouselControls}
          />
        </div>
      )}

      <div className={"listing-property-card__content"}>
        <ListingPropertyCardHeader
          property={property}
          view={view}
          listingProps={listingProps}
          editProps={editProps}
        />
        <div className={"listing-property-card__body"}>
          <p className={"listing-property-card__body__address"}>
            {`${property.street_address}, ${property.city},
             ${property.state} ${property.postal_code}`}
          </p>
          {shouldDisplayEquityList && (
            <div className={"listing-property-card__body__equity"}>
              <ListingPropertyCardEquityList
                listingProps={listingProps && {...listingProps, property}}
                property={property}
                view={view}
                shouldHideAvailableEquityLabel={shouldHideAvailableEquityLabel}
                shouldHideOwnedEquityLabel={shouldHideOwnedEquityLabel}
                shouldHideSoldAndListedEquityLabel={shouldHideSoldAndListedEquityLabel}
              />
            </div>
          )}

          {view === "small" ||
            (view === "new-home-owners" && (
              <div className={"listing-property-card__body__infolist"}>
                {listingProps?.upfront_premium && (
                  <UpfrontPremiumInfo
                    upfrontPremium={
                      listingProps?.upfront_premium ? listingProps.upfront_premium : 0
                    }
                  />
                )}
                {listingProps?.equity_dividend_amount && (
                  <EquityDividendInfo
                    equityDividend={
                      listingProps?.equity_dividend_amount
                        ? listingProps.equity_dividend_amount
                        : 0
                    }
                  />
                )}
                {view === "new-home-owners" && <EquitySummaryInfo />}
              </div>
            ))}
        </div>

        {view === "detail" && (
          <ListingPropertyCardFooter property={property} listingProps={listingProps} />
        )}

        {view === "small" &&
          (shouldDisplayBookmark || shouldDisplayShare) &&
          listingProps && (
            <ListingPropertyCardActionList
              listingProps={listingProps}
              shouldDisplayBookmark={shouldDisplayBookmark}
              shouldDisplayShare={shouldDisplayShare}
            />
          )}

        {shouldDisplayPublishButton &&
          listingProps?.status === "draft" &&
          ["small", "detail"].includes(view) &&
          listingProps?.is_owner && (
            <Link
              to={generatePath(ROUTES.LISTINGS.EDIT, {listing_id: listingProps.id})}
              className={"listing-detail-page-navigation__back"}
            >
              <Button
                isDisabled={false}
                customClassName={
                  "listing-property-card__continue-listing-button button--primary typography--caption"
                }
              >
                {"Continue Listing Process"}
              </Button>
            </Link>
          )}
      </div>
    </div>
  );

  function generateCarouselImages() {
    const propertyMedia = property.media.map((media) =>
      media.image ? generatePrismUrl({width: 468})(media.image) : media.video!
    );

    if (propertyMedia.length > 0) {
      return propertyMedia;
    }

    return [CarouselPlaceholder];
  }

  function renderSellerTypeBadge() {
    if (listingProps?.seller_type === "homeowner") {
      return (
        <Badge customClassName={"listing-property-card__badge"} color={"green"}>
          {"Homeowner"}
        </Badge>
      );
    }

    return (
      <Badge customClassName={"listing-property-card__badge"} color={"gold"}>
        {"Investor"}
      </Badge>
    );
  }
}

export default ListingPropertyCard;
