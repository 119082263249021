import {defaultNumberFormatter, formatPrice} from "../../core/util/number/numberUtils";
import {
  EquityTransactionItem,
  EquityTransactionAction
} from "../api/transactionApiModels";

function getTransactionAmount(transaction: EquityTransactionItem) {
  return transaction.amount_in_usd
    ? formatPrice(Number(transaction.amount_in_usd))
    : `${defaultNumberFormatter(Number(transaction.equity_percentage))}%`;
}

function renderTransactionTypeText(action: EquityTransactionAction) {
  let transactionTypeText;

  switch (action) {
    case "buy":
      transactionTypeText = "Equity Purchased";
      break;
    case "sell":
      transactionTypeText = "Equity Sold";
      break;

    case "transfer-in":
      transactionTypeText = "Incoming transfer";
      break;

    default:
      break;
  }
  return transactionTypeText;
}

export {getTransactionAmount, renderTransactionTypeText};
