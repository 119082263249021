import {CheckoutSessionDetail} from "../../checkout/api/checkoutApiModels";
import VestaApi, {VestaFormApi} from "../../core/network/vestaApi";
import {Offer} from "../../offer/api/offerApiModels";
import {
  CreateCheckoutSessionRequestPayload,
  CreateListingRequestPayload,
  PropertyDocument,
  MortgageDocument,
  PropertyListItem,
  PropertyMedia,
  RetrieveListingsQueryParams,
  UpdateListingRequestPayload,
  ClosingCostsLineItem,
  GetListCalculationsParams
} from "./listingApiModels";
import {ListingCalculations, PropertyAnalysis} from "./propertyAnalysisApiModels";

const LISTING_API_ROOT = "listings/";

const PROPERTY_API_ROOT = "properties/";

const listingApi = {
  getListings(params?: RetrieveListingsQueryParams) {
    return VestaApi.run<ListRequestResponse<PropertyListItem>>(
      {method: "GET", params},
      `${LISTING_API_ROOT}`
    );
  },

  createListing(payload: CreateListingRequestPayload) {
    return VestaApi.run<PropertyListItem>(
      {method: "POST", body: payload},
      `${LISTING_API_ROOT}`
    );
  },

  getBookmarkedListings() {
    return VestaApi.run<ListRequestResponse<PropertyListItem>>(
      {method: "GET"},
      `${LISTING_API_ROOT}bookmarks/`
    );
  },

  getPropertyAnalysis(propertyId: string) {
    return VestaApi.run<PropertyAnalysis>(
      {method: "GET"},
      `${PROPERTY_API_ROOT}${propertyId}/analysis/`
    );
  },

  getlistingDetail(listingId: string) {
    return VestaApi.run<PropertyListItem>(
      {method: "GET"},
      `${LISTING_API_ROOT}${listingId}/`
    );
  },

  getlistingCalculations(params: GetListCalculationsParams, listingId: string) {
    return VestaApi.run<ListingCalculations[]>(
      {method: "GET", params},
      `${LISTING_API_ROOT}${listingId}/analysis/`
    );
  },

  updateListing(payload: CreateListingRequestPayload, listingId: string) {
    return VestaApi.run(
      {method: "PUT", body: payload},
      `${LISTING_API_ROOT}${listingId}/`
    );
  },

  partiallyUpdateListing(
    payload: Partial<UpdateListingRequestPayload>,
    listingId: string
  ) {
    return VestaApi.run<PropertyListItem>(
      {method: "PATCH", body: payload},
      `${LISTING_API_ROOT}${listingId}/`
    );
  },

  deleteListing(payload: Partial<CreateListingRequestPayload>, listingId: string) {
    return VestaApi.run(
      {method: "DELETE", body: payload},
      `${LISTING_API_ROOT}${listingId}/`
    );
  },

  createCheckoutSession(payload: CreateCheckoutSessionRequestPayload, listingId: string) {
    return VestaApi.run<CheckoutSessionDetail>(
      {method: "POST", body: payload},
      `${LISTING_API_ROOT}${listingId}/checkout-sessions/`
    );
  },

  bookmarkListing(listingId: string) {
    return VestaApi.run({method: "POST"}, `${LISTING_API_ROOT}${listingId}/bookmark/`);
  },

  unbookmarkListing(listingId: string) {
    return VestaApi.run({method: "POST"}, `${LISTING_API_ROOT}${listingId}/unbookmark/`);
  },

  closeListing(listingId: string) {
    return VestaApi.run({method: "POST"}, `${LISTING_API_ROOT}${listingId}/close/`);
  },

  publishListing(listingId: string) {
    return VestaApi.run({method: "POST"}, `${LISTING_API_ROOT}${listingId}/publish/`);
  },

  createListingVisit(listingId: string) {
    return VestaApi.run({method: "POST"}, `${LISTING_API_ROOT}${listingId}/visits/`);
  },

  triggerBuyback(listingId: string) {
    return VestaApi.run(
      {method: "POST"},
      `${LISTING_API_ROOT}${listingId}/trigger-buyback/`
    );
  },

  createPropertyDocument(payload: FormData) {
    return VestaFormApi.run<PropertyDocument>(
      {method: "POST", body: payload},
      `property-documents/`
    );
  },

  createMortgageDocument(payload: FormData) {
    return VestaFormApi.run<MortgageDocument>(
      {method: "POST", body: payload},
      `mortgage-documents/`
    );
  },

  createPropertyMedia(payload: string) {
    return VestaApi.run<PropertyMedia>(
      {method: "POST", body: {image: payload}},
      `property-media/`
    );
  },

  createPropertyAsset(propertyId: string) {
    return VestaApi.run<{id: string; transaction: string}>(
      {method: "POST"},
      `properties/${propertyId}/assets/`
    );
  },

  signPropertyAsset(assetId: string, payload: {signed_transaction: string}) {
    return VestaApi.run(
      {method: "POST", body: payload},
      `assets/${assetId}/sign-transaction/`
    );
  },

  getListingOffers(listingId: string) {
    return VestaApi.run<ListRequestResponse<Offer>>(
      {method: "GET"},
      `${LISTING_API_ROOT}${listingId}/offers/`
    );
  },

  getClosingCostsLineItems(listingId: string) {
    return VestaApi.run<ListRequestResponse<ClosingCostsLineItem>>(
      {method: "GET"},
      `${LISTING_API_ROOT}${listingId}/closing-costs-line-items/`
    );
  }
};

export default listingApi;
