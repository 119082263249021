function isObject(x: unknown): x is Record<string, any> {
  return typeof x === "object" && Boolean(x) && !Array.isArray(x);
}

function filterObject<Obj extends Record<string, any>>(
  predicateFn: (key: keyof Obj, value: any) => boolean
) {
  return (object: Obj) =>
    Object.entries(object).reduce((filtered, [key, value]) => {
      if (predicateFn(key, value)) {
        // @ts-ignore 'string' can't be used to index type '{}'
        filtered[key] = value;
      }

      return filtered;
    }, {}) as Obj;
}

const filterTruthyObjectValues = filterObject((_key, value) => Boolean(value));

/**
 * Picks provided fields from main object and returns new object which includes picked values
 * @param obj Target object
 * @param keys Keys to select from obj
 */
function pickKeys<T extends {[x: string]: any}, K extends keyof T>(obj: T, ...keys: K[]) {
  const newObj: {[x: string]: any} = {};

  keys.forEach((key) => {
    newObj[key as string] = obj[key];
  });

  return newObj as Omit<T, Exclude<keyof T, K>>;
}

/**
 * Omits provided fields from main object
 * @param obj Target object
 * @param keys Keys to omit from obj
 */
function omitKeys<T extends {[x: string]: any}, K extends keyof T>(obj: T, ...keys: K[]) {
  const newObj: {[x: string]: any} = {};

  Object.keys(obj).forEach((key) => {
    // @ts-ignore 'string' is assignable to the constraint of type 'K', but 'K' could be instantiated with a different subtype of constraint 'string | number | symbol'.
    // couldn't solve this issue
    if (!keys.includes(key)) {
      newObj[key as string] = obj[key];
    }
  });

  return newObj as Omit<T, K>;
}

function removeNullishValuesFromObject<T>(params: T) {
  return Object.keys(params)
    .filter((key) => params[key as keyof T] !== undefined)
    .reduce(
      (accumulator, key) => ({...accumulator, [key as keyof T]: params[key as keyof T]}),
      {}
    );
}

export {
  isObject,
  filterObject,
  filterTruthyObjectValues,
  pickKeys,
  omitKeys,
  removeNullishValuesFromObject
};
