import {ReactComponent as UpfrontPremium} from "../../../../core/ui/icon/listing/Upfront_Premium_Icon_circle.svg";

import {formatNumber} from "../../../../core/util/number/numberUtils";

import "./_upfront-premium-info.scss";

interface UpfrontPremiumInfoProps {
  upfrontPremium: number;
}

function UpfrontPremiumInfo({upfrontPremium}: UpfrontPremiumInfoProps) {
  return (
    <div className={"upfront-premium-info__upfront"}>
      <div className={"upfront-premium-info__upfront__container"}>
        <div className={"upfront-premium-info__upfront__container__available"}>
          <div className={"upfront-premium-info__upfront__container__available__icon"}>
            <UpfrontPremium width={18.2} height={18.2} />
          </div>
          <div className={"upfront-premium-info__upfront__container__available__text"}>
            <div
              className={"upfront-premium-info__upfront__container__available__percent"}
            >{`${formatNumber({
              maximumFractionDigits: 1
            })(upfrontPremium)}%`}</div>
            <div className={"upfront-premium-info__upfront__container__available__title"}>
              {"Available"}
            </div>
          </div>
        </div>
        <div className={"upfront-premium-info__upfront__container__allocated"}>
          <div className={"upfront-premium-info__upfront__container__available__text"}>
            <div
              className={"upfront-premium-info__upfront__container__available__percent"}
            >{`0.000%`}</div>
            <div className={"upfront-premium-info__upfront__container__available__title"}>
              {"Allocated"}
            </div>
          </div>
        </div>
      </div>
      {"Upfront Premium"}
    </div>
  );
}

export default UpfrontPremiumInfo;
