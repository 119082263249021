import "./_badge-card-style.scss";

import classNames from "classnames";

interface BadgeProps {
  children: React.ReactNode;
  type?: "monochrome" | "duetone" | undefined;
  color?: "teal" | "gold" | "green" | "red" | "sunray" | "pink";
  customClassName?: string;
}

function BadgeCardStyle({
  children,
  type = "monochrome",
  color = "teal",
  customClassName
}: BadgeProps) {
  const className = classNames(
    "badge-card-style",
    customClassName,
    `badge-card-style--color--${color}`,
    `badge-card-style--type--${type}`
  );

  return (
    <div className={className}>
      <div className={"badge__body"}>{children}</div>
    </div>
  );
}

export default BadgeCardStyle;
