import {format, formatDistanceToNow, subYears, formatDistance, subDays} from "date-fns";

import {DATE_FORMAT, ONE_DAY, MIN_ACCEPTED_AGE} from "./dateConstants";

function formatDate(date: Date, dateFormat = DATE_FORMAT.YEAR_MONTH_DAY_FORMAT) {
  return format(date, dateFormat);
}

function parseDateInUTC(date?: string) {
  const dateString = date || formatBirthDate(new Date());
  let newDate = new Date(dateString);

  const [year, month, day] = dateString.split("-");

  newDate = new Date(Number(year), Number(month) - 1, Number(day));

  return newDate;
}

function formatBirthDate(date: Date) {
  const year = date.getFullYear();
  // Date provides month index; not month number
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${padToTwo(month)}-${padToTwo(day)}`;

  function padToTwo(number: number) {
    // eslint-disable-next-line no-magic-numbers
    return number > 9 ? number : `0${number}`;
  }
}

function getDefaultBirthDate() {
  return subYears(parseDateInUTC(), MIN_ACCEPTED_AGE);
}

function formatDateDistance(end: Date) {
  const distance = Date.now() - end.getTime();

  if (distance < ONE_DAY && distance > 0) {
    return "today";
  }
  return formatDistanceToNow(end, {addSuffix: false});
}

function formatDateAsDaysAgo(date: Date) {
  return formatDistance(subDays(date, 0), new Date(), {addSuffix: true});
}

export {
  formatDate,
  getDefaultBirthDate,
  formatDateDistance,
  formatDateAsDaysAgo,
  formatBirthDate,
  parseDateInUTC
};
