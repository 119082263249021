import "./_pagination.scss";

import {useEffect, useState} from "react";
import {List, ListItem} from "@hipo/react-ui-toolkit";
import classNames from "classnames";

import {constructPaginationItems} from "./util/paginationUtils";

interface PaginationProps {
  activePage: number;
  onPageChange: (pageNumber: number) => void;
  totalItemCount: number;
  perPage?: number;
  customClassName?: string;
  ariaLabel?: string;
  testid?: string;
}

const DEFAULT_ITEM_COUNT_PER_PAGE = 3;

function Pagination({
  activePage,
  onPageChange,
  totalItemCount,
  perPage = DEFAULT_ITEM_COUNT_PER_PAGE,
  ariaLabel,
  customClassName,
  testid
}: PaginationProps) {
  const [displayedPages, setDisplayedPages] = useState<number[]>([]);

  useEffect(() => {
    setDisplayedPages(constructPaginationItems({totalItemCount, perPage}, activePage));
  }, [totalItemCount, activePage, perPage]);

  return displayedPages.length ? (
    <List
      items={displayedPages}
      customClassName={classNames("align-center--vertically", customClassName)}
      aria-label={ariaLabel}
      testid={testid}
      listItemKeyGenerator={(item, itemTestId) => `${itemTestId}.page-${item}`}
    >
      {(item) => {
        const isNonInteractive = !item || item === activePage;

        return (
          <ListItem
            customClassName={classNames("pagination__item", {
              "pagination__item--is-active": item === activePage
            })}
            aria-label={
              item === activePage ? `Current Page, Page ${item}` : `Go to page ${item}`
            }
            clickableListItemProps={
              isNonInteractive
                ? undefined
                : {
                    onClick: handlePageChange(item)
                  }
            }
            ariaSelected={item === activePage}
          >
            {item || "..."}
          </ListItem>
        );
      }}
    </List>
  ) : null;

  function handlePageChange(page: number) {
    return () => {
      onPageChange(page);
    };
  }
}

export default Pagination;
