import "./_portfolio_page.scss";

import Header from "../../core/app/header/Header";
import AppLayout from "../../core/app/layout/AppLayout";
import PortfolioPropertyCard from "../property/card/PortfolioPropertyCard";
import PortfolioAnalyticsCard from "../analytics/card/PortfolioAnalyticsCard";
import PortfolioRecentActivityCard from "../recent-activity/card/PortfolioRecentActivityCard";

function PortfolioPage() {
  return (
    <AppLayout>
      <Header
        currentPageTitle={"Your Portfolio"}
        shouldDisplaySearch={true}
        shouldDisplayUserProfileRow={true}
      />

      <main className={"portfolio-page"}>
        <PortfolioAnalyticsCard />
        <PortfolioRecentActivityCard />
        <PortfolioPropertyCard />
      </main>
    </AppLayout>
  );
}

export default PortfolioPage;
