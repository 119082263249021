import "./_footer.scss";

import getYear from "date-fns/getYear";

import FooterLinks from "./link/FooterLinks";

function Footer() {
  return (
    <footer className={"footer"}>
      <h2 className={"footer__title typography--h2"}>{"Live in prosperity"}</h2>

      <FooterLinks type={"internal"} />

      <div className={"footer__social-and-copyright"}>
        <FooterLinks type={"social"} />

        <p className={"footer__copyright typography--caption"}>
          {`Copyright © ${getYear(new Date())} Vesta Equity. All rights reserved.`}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
