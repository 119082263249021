import "./_header.scss";

import {ReactComponent as HomeownerIcon} from "../../../core/ui/icon/homeowner.svg";
import {ReactComponent as InvestorIcon} from "../../../core/ui/icon/investor.svg";

import {useHistory} from "react-router-dom";
import {Button} from "@hipo/react-ui-toolkit";
import {useEffect} from "react";

import ROUTES from "../../../core/route/routes";
import UserProfileRow from "../../../user/profile/row/UserProfileRow";
import useAppContext from "../../../core/app/util/hook/useAppContext";

interface HeaderProps {
  currentPageTitle?: string;
  shouldDisplaySearch?: boolean;
  shouldDisplayUserProfileRow?: boolean;
}

function Header({
  currentPageTitle,
  shouldDisplaySearch,
  shouldDisplayUserProfileRow
}: HeaderProps) {
  const {
    appState: {currentUser}
  } = useAppContext();

  const history = useHistory();

  useEffect(() => {
    if (currentPageTitle) {
      document.title = `${currentPageTitle} - Vesta Equity`;
    }

    return () => {
      document.title = `Vesta Equity`;
    };
  }, [currentPageTitle]);

  return (
    <header className={"header"}>
      <h1 className={"typography--h1"}>{currentPageTitle}</h1>
      {currentUser === null && (
        <div className={"header__login-buttons"}>
          <Button
            onClick={handleGoToHomeownerSignupPage}
            customClassName={"button--primary button--rounded"}
          >
            {"Create an Account"}
          </Button>
          <Button
            onClick={handleGoToLoginPage}
            customClassName={"button--white button--rounded"}
          >
            {"Login"}
          </Button>
        </div>
      )}

      {shouldDisplayUserProfileRow && (
        <UserProfileRow shouldDisplaySearch={shouldDisplaySearch} />
      )}
    </header>
  );

  function handleGoToHomeownerSignupPage() {
    history.push(ROUTES.ONBOARDING.SIGNUP);
  }

  function handleGoToInvestorSignupPage() {
    history.push(ROUTES.ONBOARDING.INVESTOR.SIGNUP);
  }

  function handleGoToLoginPage() {
    history.push(ROUTES.AUTH.LOGIN);
  }
}

export default Header;
