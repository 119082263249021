function isOnProduction() {
  return process.env.REACT_APP_BUILD_ENVIRONMENT === "production";
}

function isOnStaging() {
  return process.env.REACT_APP_BUILD_ENVIRONMENT === "staging";
}

function isOnLocal() {
  return process.env.REACT_APP_BUILD_ENVIRONMENT === "local";
}

/**
 * Helps getting the value of an environment variable safely,
 * Throws an error if a value can not be found that matches the given key.
 */
function getEnvVariable(key: string): string {
  const envVariable = process.env[key];

  if (!envVariable) {
    throw new Error(`Couldn't find environment variable: ${key}`);
  }

  return envVariable;
}

export {isOnProduction, isOnStaging, isOnLocal, getEnvVariable};
