import "./_tab-header-content.scss";

import classNames from "classnames";

interface TabHeaderContentProps {
  title: string;
  value?: string | number;
  customClassName?: string;
}

function TabHeaderContent({title, value, customClassName}: TabHeaderContentProps) {
  return (
    <div className={classNames("tab-header-content", customClassName)}>
      <p className={"tab-header-content__title typography--h3"}>{title}</p>
      <span className={"tab-header-content__value"}>{value}</span>
    </div>
  );
}

export default TabHeaderContent;
