import {ReactComponent as BedroomIcon} from "../../../../core/ui/icon/listing/bedroom.svg";
import {ReactComponent as BathroomIcon} from "../../../../core/ui/icon/listing/bathroom.svg";
import {ReactComponent as CarParkingIcon} from "../../../../core/ui/icon/listing/carparking.svg";
import {ReactComponent as FloorIcon} from "../../../../core/ui/icon/listing/floor.svg";
import {ReactComponent as SqftIcon} from "../../../../core/ui/icon/listing/sqft.svg";
import {ReactComponent as ResidentailIcon} from "../../../../core/ui/icon/listing/home.svg";
import {ReactComponent as SingleFamilyIcon} from "../../../../core/ui/icon/listing/singleFam.svg";
import {ReactComponent as BuiltIcon} from "../../../../core/ui/icon/listing/buildDate.svg";
import {ReactComponent as MultiFamilyIcon} from "../../../../core/ui/icon/listing/multi-fam.svg";
import {ReactComponent as TownHouseIcon} from "../../../../core/ui/icon/listing/TownHome.svg";
import {ReactComponent as CondoIcon} from "../../../../core/ui/icon/listing/Condo.svg";
import {ReactComponent as MobileHomeIcon} from "../../../../core/ui/icon/listing/MobileHome.svg";
import {ReactComponent as LandIcon} from "../../../../core/ui/icon/listing/land.svg";
import {ReactComponent as TimeShareIcon} from "../../../../core/ui/icon/listing/TimeShare.svg";
import {ReactComponent as CommercialIcon} from "../../../../core/ui/icon/listing/commercial.svg";
import {ReactComponent as OtherIcon} from "../../../../core/ui/icon/listing/other.svg";

import {Property} from "../../../api/listingApiModels";

interface ListingPropertyCardFooterProps {
  property: Property;
}

interface ListingPropertyFooterItemProps {
  title: string;
  icon: React.ReactNode;
  value: string | number;
}

function generatePropertyFooterItems({property}: ListingPropertyCardFooterProps) {
  const items: ListingPropertyFooterItemProps[] = [
    {
      icon: <BedroomIcon />,
      title: "Bedrooms",
      value: property.details.bedroom_count
    },
    {
      icon: <BathroomIcon />,
      title: "Bathrooms",
      value:
        property.details.partial_bath_count &&
        property.details.partial_bath_count !== "0.0"
          ? property.details.bathroom_count
          : property.details.full_bath_count
    },
    {
      icon: <CarParkingIcon />,
      title: "Car Parking",
      value: property.details.parking_space_count
    },
    {
      icon: <FloorIcon />,
      title: "Floor Count",
      value: property.details.number_of_stories
    },
    {
      icon: <SqftIcon />,
      title: "sqft",
      value: property.details.square_feet
    },
    {
      icon: <ResidentailIcon />,
      title: "",
      value:
        property.details.property_type === "single-family-residential" ||
        property.details.property_type === "multi-family" ||
        property.details.property_type === "townhouse" ||
        property.details.property_type === "condominium" ||
        property.details.property_type === "manufactured/mobile-home"
          ? "Residential"
          : ""
    },
    {
      icon: <SingleFamilyIcon />,
      title: "",
      value:
        property.details.property_type === "single-family-residential" ||
        property.details.property_type === "manufactured/mobile-home"
          ? "Single Family"
          : ""
    },
    {
      icon: <MultiFamilyIcon />,
      title: "",
      value:
        property.details.property_type === "multi-family" ||
        property.details.property_type === "townhouse" ||
        property.details.property_type === "condominium"
          ? "Multi-Family"
          : ""
    },
    {
      icon: <TownHouseIcon />,
      title: "",
      value: property.details.property_type === "townhouse" ? "Townhouse" : ""
    },
    {
      icon: <CondoIcon />,
      title: "",
      value: property.details.property_type === "condominium" ? "Condominium" : ""
    },
    {
      icon: <MobileHomeIcon />,
      title: "",
      value:
        property.details.property_type === "manufactured/mobile-home" ? "Mobile Home" : ""
    },
    {
      icon: <LandIcon />,
      title: "",
      value: property.details.property_type === "land" ? "Land" : ""
    },
    {
      icon: <TimeShareIcon />,
      title: "",
      value: property.details.property_type === "timeshare" ? "Timeshare" : ""
    },
    {
      icon: <CommercialIcon />,
      title: "",
      value: property.details.property_type === "commercial" ? "Commercial" : ""
    },
    {
      icon: <OtherIcon />,
      title: "",
      value: property.details.property_type === "other" ? "Other" : ""
    },
    {
      icon: <BuiltIcon />,
      title: "",
      value: property.details.year_built
    }
  ];

  return items.filter((item) => Boolean(item.value));
}

export default generatePropertyFooterItems;
