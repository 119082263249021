import {useEffect, useReducer} from "react";
import {useHistory} from "react-router";

import OnboardingContext, {
  initialOnboardingState,
  onboardingStateReducer
} from "./OnboardingContext";
import useAppContext from "../../../core/app/util/hook/useAppContext";

interface OnboardingContextProviderProps {
  children: React.ReactNode;
}

function OnboardingContextProvider({children}: OnboardingContextProviderProps) {
  const history = useHistory();
  const [onboardingState, dispatchOnboardingStateAction] = useReducer(
    onboardingStateReducer,
    initialOnboardingState
  );
  const {
    appState: {currentUser}
  } = useAppContext();
  const onboardingType = history.location.pathname.includes("/investor/")
    ? "investor"
    : currentUser?.onboarding_type || "homeowner";

  useEffect(() => {
    if (currentUser?.onboarding_type) {
      dispatchOnboardingStateAction({
        type: "SET_TYPE",
        payload: {
          type: currentUser?.onboarding_type
        }
      });
    } else if (["homeowner", "investor"].includes(onboardingType)) {
      dispatchOnboardingStateAction({
        type: "SET_TYPE",
        payload: {
          type: onboardingType
        }
      });
    }

    if (currentUser?.next_required_onboarding_step) {
      dispatchOnboardingStateAction({
        type: "SET_STEP",
        payload: {
          step: currentUser.next_required_onboarding_step
        }
      });
    }
  }, [currentUser, onboardingType]);

  return (
    <OnboardingContext.Provider value={{onboardingState, dispatchOnboardingStateAction}}>
      {children}
    </OnboardingContext.Provider>
  );
}

export default OnboardingContextProvider;
