import {useEffect, useReducer} from "react";
import {useHistory, useLocation, matchPath} from "react-router";

import {AuthUserProfileModel} from "../../../user/api/userApiModels";
import useAsyncProcess from "../../network/async-process/useAsyncProcess";
import AppContext, {appStateReducer, initialAppState} from "./AppContext";
import userApi from "../../../user/api/userApi";
import ROUTES from "../../route/routes";
import RouteLoading from "../../../components/route-loading/RouteLoading";
import useRedirectOnboardingStep from "../../../user/onboarding/util/hook/useRedirectOnboardingStep";
import webStorage from "../../util/storage/webStorage";
import {identifyUserOnHubspot} from "../util/hubspotUtils";

interface AppContextProviderProps {
  children: React.ReactNode;
}

function AppContextProvider({children}: AppContextProviderProps) {
  const history = useHistory();
  const location = useLocation();
  const [appState, dispatchAppStateAction] = useReducer(appStateReducer, initialAppState);
  const {state: currentUserRequestState, runAsyncProcess} =
    useAsyncProcess<AuthUserProfileModel>();
  const shouldDisplayRouteLoading =
    location.pathname.includes("onboarding") && currentUserRequestState.isRequestPending;

  useRedirectOnboardingStep({
    currentUser: currentUserRequestState.data,
    shouldStart: currentUserRequestState.isRequestFetched
  });

  useEffect(() => {
    (async () => {
      const hasCSRFToken = Boolean(
        webStorage.cookie.getCookie(process.env.REACT_APP_CSRF_TOKEN_NAME || "csrftoken")
      );

      const isOnProtectedRoute =
        location.pathname !== ROUTES.AUTH.LOGIN &&
        location.pathname !== ROUTES.RESET_PASSWORD &&
        location.pathname !== ROUTES.NEW_PASSWORD &&
        !Object.values(ROUTES.ONBOARDING).includes(
          location.pathname as ValueOf<typeof ROUTES.ONBOARDING>
        ) &&
        !Object.values(ROUTES.ONBOARDING.INVESTOR).includes(
          location.pathname as ValueOf<typeof ROUTES.ONBOARDING.INVESTOR>
        ) &&
        !matchPath(location.pathname, {path: ROUTES.LISTINGS.DETAIL, exact: true}) &&
        !matchPath(location.pathname, {
          path: ROUTES.MARKETPLACE.LISTING_DETAIL,
          exact: true
        }) &&
        !matchPath(location.pathname, {
          path: ROUTES.MARKETPLACE.LISTING_MEDIA,
          exact: true
        }) &&
        !matchPath(location.pathname, {
          path: ROUTES.MARKETPLACE.ROOT,
          exact: true
        });

      if (!hasCSRFToken && isOnProtectedRoute) {
        history.replace(ROUTES.AUTH.LOGIN);
      }

      try {
        const currentUser = await runAsyncProcess(userApi.getAuthUser());

        dispatchAppStateAction({
          type: "SET_CURRENT_USER",
          payload: {currentUser}
        });

        identifyUserOnHubspot(currentUser);

        // Redirect user to Dashboard if they're logged in
        if (location.pathname === ROUTES.AUTH.LOGIN) {
          history.replace(ROUTES.BASE);
        }
      } catch (_error) {
        // Redirect to Marketplace if the user is not logged in and arrived at /
        if (location.pathname === ROUTES.BASE) {
          history.replace(ROUTES.MARKETPLACE.ROOT);
        }
        // Redirect user to LoginPage if they're not logged in
        else if (isOnProtectedRoute && !currentUserRequestState.isRequestPending) {
          history.replace(ROUTES.AUTH.LOGIN);
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, runAsyncProcess]);

  return (
    <AppContext.Provider value={{appState, dispatchAppStateAction}}>
      {shouldDisplayRouteLoading ? <RouteLoading /> : children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
