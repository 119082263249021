import {ReactComponent as LinkedinIcon} from "../../../../ui/icon/linkedin.svg";
import {ReactComponent as InstagramIcon} from "../../../../ui/icon/instagram.svg";
import {ReactComponent as FacebookIcon} from "../../../../ui/icon/facebook.svg";
import {ReactComponent as TwitterIcon} from "../../../../ui/icon/twitter.svg";

import {FooterLinkItem} from "./footerTypes";

const footerInternalLinks: FooterLinkItem[] = [
  {id: "home", title: "Home", href: "https://vestaequity.net"},
  {id: "about-us", title: "About Us", href: "https://vestaequity.net/about/"},
  {
    id: "help",
    title: "Help",
    href: "https://vestaequity.net/help/"
  },
  {
    id: "resources",
    title: "Resources",
    href: "https://vestaequity.net/resources/"
  },
  {id: "faqs", title: "FAQs", href: "https://vestaequity.net/faq/"},
  {id: "privacy", title: "Privacy", href: "https://vestaequity.net/privacy-policy/"},
  {id: "terms", title: "Terms", href: "https://vestaequity.net/terms/"}
];

const footerSocialLinks: FooterLinkItem[] = [
  {
    id: "linkedin",
    icon: <LinkedinIcon />,
    href: "https://www.linkedin.com/company/vestaequityvpm/"
  },
  {
    id: "instagram",
    icon: <InstagramIcon />,
    href: "https://www.instagram.com/vestaequityvpm/"
  },
  {
    id: "facebook",
    icon: <FacebookIcon />,
    href: "https://www.facebook.com/vestaequityvpm"
  },
  {id: "twitter", icon: <TwitterIcon />, href: "https://x.com/vestaequityvpm"}
];

export {footerInternalLinks, footerSocialLinks};
