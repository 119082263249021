import "./_listing-property-detail-item.scss";

export interface ListingPropertyDetailItemProps {
  title: string;
  icon: React.ReactNode;
  value: string | number;
}

function ListingPropertyDetailItem({title, icon, value}: ListingPropertyDetailItemProps) {
  return (
    <div className={"listing-property-detail-item"}>
      <div className={"listing-property-detail-item__content"}>{icon}</div>
      <div>
        <p className={"listing-property-detail-item__title typography--caption"}>
          {`${value} ${title}`}
        </p>
      </div>
    </div>
  );
}

export default ListingPropertyDetailItem;
