import {lazy, Suspense} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {ToastContextProvider} from "@hipo/react-ui-toolkit";

import ROUTES from "../route/routes";
import RouteLoading from "../../components/route-loading/RouteLoading";
import AppContextProvider from "./context/AppContextProvider";
import OnboardingContextProvider from "../../user/onboarding/context/OnboardingContextProvider";
import ModalContextProvider from "../../components/modal/context/ModalContextProvider";
import CreateListingContextProvider from "../../listing/create/context/CreateListingContextProvider";
import PortfolioPage from "../../portfolio/page/PortfolioPage";

const LoginPage = lazy(
  () =>
    import(
      /* webpackChunkName: "login-page" */ "../../user/authentication/page/login/LoginPage"
    )
);
const SignupPage = lazy(
  () =>
    import(
      /* webpackChunkName: "signup-page" */ "../../user/onboarding/signup/SignupPage"
    )
);
const EmailVerificationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "email-verification-page" */ "../../user/onboarding/email-verification/EmailVerificationPage"
    )
);
const IDVerificationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "id-verification-page" */ "../../user/onboarding/id-verification/IDVerificationPage"
    )
);
const OnboardingTypePage = lazy(
  () =>
    import(
      /* webpackChunkName: "onboarding-type-page" */ "../../user/onboarding/onboarding-type/OnboardingTypePage"
    )
);
const KYCAndAMLPage = lazy(
  () =>
    import(
      /* webpackChunkName: "kyc-and-aml-page" */ "../../user/onboarding/kyc/KYCAndAMLPage"
    )
);
const WalletConnectionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "wallet-connection-page" */ "../../user/onboarding/wallet/WalletConnectionPage"
    )
);
const OnboardingSuccessPage = lazy(
  () =>
    import(
      /* webpackChunkName: "onboarding-success-page" */ "../../user/onboarding/success/OnboardingSuccessPage"
    )
);

const ListingsPage = lazy(
  () => import(/* webpackChunkName: "listings-page" */ "../../listing/page/ListingsPage")
);

const ListingDetailPage = lazy(
  () =>
    import(
      /* webpackChunkName: "listings-detail-page" */ "../../listing/detail/page/ListingDetailPage"
    )
);

const CreateListingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "create-listing-page" */ "../../listing/create/page/CreateListingPage"
    )
);

const MarketplacePage = lazy(
  () =>
    import(
      /* webpackChunkName: "marketplace-page" */ "../../marketplace/page/MarketplacePage"
    )
);

const OffersPage = lazy(
  () => import(/* webpackChunkName: "offers-page" */ "../../offer/page/OffersPage")
);

const DashboardPage = lazy(
  () =>
    import(/* webpackChunkName: "dashboard-page" */ "../../dashboard/page/DashboardPage")
);

const SettingsPage = lazy(
  () => import(/* webpackChunkName: "settings-page" */ "../../settings/page/SettingsPage")
);

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<RouteLoading />}>
        <AppContextProvider>
          <ToastContextProvider autoCloseToasts={false}>
            <ModalContextProvider>
              <Switch>
                <Route path={ROUTES.HOME} exact={true} component={DashboardPage} />

                <Route path={ROUTES.AUTH.LOGIN} exact={true} component={LoginPage} />

                <Route path={ROUTES.RESET_PASSWORD} exact={true} component={LoginPage} />

                <Route path={ROUTES.NEW_PASSWORD} exact={true} component={LoginPage} />

                <Route
                  path={ROUTES.LISTINGS.ROOT}
                  exact={true}
                  component={ListingsPage}
                />
                <Route exact={true} path={[ROUTES.LISTINGS.EDIT, ROUTES.LISTINGS.CREATE]}>
                  <CreateListingContextProvider>
                    <CreateListingPage />
                  </CreateListingContextProvider>
                </Route>

                <Route exact={true} path={[ROUTES.LISTINGS.EDIT, ROUTES.LISTINGS.CREATE]}>
                  <CreateListingContextProvider>
                    <CreateListingPage />
                  </CreateListingContextProvider>
                </Route>

                <Route path={[ROUTES.LISTINGS.EDIT, ROUTES.LISTINGS.CREATE]}>
                  <CreateListingContextProvider>
                    <CreateListingPage />
                  </CreateListingContextProvider>
                </Route>

                <Route
                  path={[
                    ROUTES.LISTINGS.DETAIL,

                    ROUTES.MARKETPLACE.LISTING_DETAIL,

                    ROUTES.PORTFOLIO.DETAIL,

                    ROUTES.LISTINGS.MEDIA,

                    ROUTES.MARKETPLACE.LISTING_MEDIA
                  ]}
                  exact={true}
                  component={ListingDetailPage}
                />

                <Route
                  path={ROUTES.MARKETPLACE.ROOT}
                  exact={true}
                  component={MarketplacePage}
                />

                <Route
                  path={ROUTES.PORTFOLIO.ROOT}
                  exact={true}
                  component={PortfolioPage}
                />
                <Route path={ROUTES.OFFERS} exact={true} component={OffersPage} />

                <Route path={ROUTES.SETTINGS} exact={true} component={SettingsPage} />
              </Switch>

              <Switch>
                <OnboardingContextProvider>
                  <Route
                    path={[ROUTES.ONBOARDING.SIGNUP, ROUTES.ONBOARDING.INVESTOR.SIGNUP]}
                    exact={true}
                    component={SignupPage}
                  />
                  <Route
                    path={ROUTES.ONBOARDING.EMAIL_VERIFICATION}
                    exact={true}
                    component={EmailVerificationPage}
                  />
                  <Route
                    path={ROUTES.ONBOARDING.ID_VERIFICATION}
                    exact={true}
                    component={IDVerificationPage}
                  />
                  <Route
                    path={ROUTES.ONBOARDING.ONBOARDING_TYPE}
                    exact={true}
                    component={OnboardingTypePage}
                  />
                  <Route
                    path={ROUTES.ONBOARDING.KYC}
                    exact={true}
                    component={KYCAndAMLPage}
                  />
                  <Route
                    path={ROUTES.ONBOARDING.WALLET_CREATION}
                    exact={true}
                    component={WalletConnectionPage}
                  />
                  <Route
                    path={ROUTES.ONBOARDING.SUCCESS}
                    exact={true}
                    component={OnboardingSuccessPage}
                  />
                </OnboardingContextProvider>
              </Switch>
            </ModalContextProvider>
          </ToastContextProvider>
        </AppContextProvider>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
