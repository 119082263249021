import {createContext, Dispatch} from "react";

import {OnboardingStep, OnboardingType} from "../../../user/api/userApiModels";

interface OnboardingState {
  type: OnboardingType;
  step: OnboardingStep | null;
}

const initialOnboardingState: OnboardingState = {
  type: "homeowner",
  step: null
} as const;

type OnboardingStateAction =
  | {
      type: "SET_STEP";
      payload: {
        step: OnboardingStep | null;
      };
    }
  | {
      type: "SET_TYPE";
      payload: {
        type: OnboardingType;
      };
    };

function onboardingStateReducer(
  state = initialOnboardingState,
  action: OnboardingStateAction
) {
  let newState = state;

  switch (action.type) {
    case "SET_TYPE":
    case "SET_STEP": {
      newState = {
        ...state,
        ...action.payload
      };

      break;
    }

    default:
      break;
  }

  return newState;
}

const OnboardingContext = createContext({
  onboardingState: initialOnboardingState,
  dispatchOnboardingStateAction: (() => undefined) as Dispatch<OnboardingStateAction>
});

export default OnboardingContext;
export {onboardingStateReducer, initialOnboardingState};
