import {ReactComponent as UserPlaceholder} from "../../core/ui/icon/user-placeholder.svg";

import "./_avatar.scss";

import classNames from "classnames";

interface AvatarProps {
  alt: string;
  src?: string | null;
  size?: {width: number; height: number};
  customClassName?: string;
}

function Avatar({src, alt, size, customClassName}: AvatarProps) {
  return src ? (
    <img
      className={classNames("avatar", customClassName)}
      src={src}
      alt={alt}
      style={{...size}}
    />
  ) : (
    <div className={"avatar__placeholder"}>
      <UserPlaceholder />
    </div>
  );
}

export default Avatar;
