import VestaApi, {VestaFormApi} from "../../core/network/vestaApi";
import {
  ListingCounts,
  PropertyListItem,
  Property
} from "../../listing/api/listingApiModels";
import {PropertyCounts} from "../../property/api/propertyApiModels";
import {
  EquityTransactionItem,
  TransactionItem
} from "../../transaction/api/transactionApiModels";
import {
  AccreditedInvestorVerificationDocument,
  AccreditedInvestorVerificationRequest,
  AnswerKycAmlRequestPayload,
  AuthUserProfileModel,
  SentForgottenPasswordEmailPayload,
  ChangeForgottenPasswordRequestPayload,
  ChangePasswordRequestPayload,
  CreateKycAmlRequestResponse,
  LoginRequestPayload,
  RegisterRequestPayload,
  RetrieveAuthUserListingsQueryParams,
  RetrieveAuthUserPropertiesQueryParams
} from "./userApiModels";

const USER_API_ROOT = "users/";

const userApi = {
  login(payload: LoginRequestPayload) {
    return VestaApi.run(
      {
        method: "POST",
        body: payload
      },
      `${USER_API_ROOT}authenticate/`
    );
  },

  logout() {
    return VestaApi.run({method: "POST"}, `${USER_API_ROOT}logout/`);
  },

  register(payload: RegisterRequestPayload) {
    return VestaApi.run({method: "POST", body: payload}, `${USER_API_ROOT}register/`);
  },

  getAuthUser() {
    return VestaApi.run<AuthUserProfileModel>(
      {
        method: "GET"
      },
      `${USER_API_ROOT}me/`
    );
  },

  updateAuthUser(payload: AuthUserProfileModel) {
    return VestaApi.run<AuthUserProfileModel>(
      {method: "PUT", body: payload},
      `${USER_API_ROOT}me/`
    );
  },

  partiallyUpdateAuthUser(payload: Partial<AuthUserProfileModel>) {
    return VestaApi.run<AuthUserProfileModel>(
      {method: "PATCH", body: payload},
      `${USER_API_ROOT}me/`
    );
  },

  sendVerificationEmail() {
    return VestaApi.run(
      {method: "POST"},
      `${USER_API_ROOT}me/send-email-verification-email/`
    );
  },

  verifyEmailByCode(code: string) {
    return VestaApi.run(
      {method: "POST", body: {code}},
      `${USER_API_ROOT}me/verify-email/code/`
    );
  },

  verifyEmailByKey(code: string) {
    return VestaApi.run(
      {method: "POST", body: {code}},
      `${USER_API_ROOT}me/verify-email/key/`
    );
  },

  sendForgottenPasswordEmail(payload: SentForgottenPasswordEmailPayload) {
    return VestaApi.run(
      {method: "POST", body: payload},
      `${USER_API_ROOT}send-forgotten-password-email/`
    );
  },

  changeForgettenPassword(payload: ChangeForgottenPasswordRequestPayload) {
    return VestaApi.run(
      {method: "POST", body: payload},
      `${USER_API_ROOT}change-forgotten-password/`
    );
  },

  changePassword(payload: ChangePasswordRequestPayload) {
    return VestaApi.run(
      {method: "POST", body: payload},
      `${USER_API_ROOT}change-password/`
    );
  },

  getAuthUserListings(params?: RetrieveAuthUserListingsQueryParams) {
    return VestaApi.run<ListRequestResponse<PropertyListItem>>(
      {method: "GET", params},
      `${USER_API_ROOT}me/listings/`
    );
  },

  getAuthUserListingCounts() {
    return VestaApi.run<ListingCounts>(
      {method: "GET"},
      `${USER_API_ROOT}me/listing-counts/`
    );
  },

  createKycAml() {
    return VestaApi.run<CreateKycAmlRequestResponse>(
      {method: "POST"},
      `${USER_API_ROOT}me/kyc-aml-requests/`
    );
  },

  answerKycAmlQuestions(payload: AnswerKycAmlRequestPayload) {
    return VestaApi.run<CreateKycAmlRequestResponse>(
      {method: "POST", body: payload},
      `${USER_API_ROOT}me/kyc-aml-requests/answers/`
    );
  },

  uploadAccreditedInvestorVerificationDocuments(payload: FormData) {
    return VestaFormApi.run(
      {method: "POST", body: payload},
      `${USER_API_ROOT}me/accredited-investor-verification-documents/`
    );
  },

  retrieveAccreditedInvestorVerificationRequest() {
    return VestaApi.run<AccreditedInvestorVerificationRequest>(
      {method: "GET"},
      `${USER_API_ROOT}me/accredited-investor-verification-request/`
    );
  },

  getAccreditedInvestorVerificationDocuments() {
    return VestaApi.run<ListRequestResponse<AccreditedInvestorVerificationDocument>>(
      {method: "GET"},
      `${USER_API_ROOT}me/accredited-investor-verification-documents/`
    );
  },

  accreditedInvestorVerificationRequest() {
    return VestaApi.run<AccreditedInvestorVerificationRequest>(
      {method: "POST"},
      `${USER_API_ROOT}me/accredited-investor-verification-request/`
    );
  },

  accreditedInvestorVerificationRequestTrigger() {
    return VestaApi.run<AccreditedInvestorVerificationRequest>(
      {method: "POST"},
      `${USER_API_ROOT}me/accredited-investor-verification-request/new-info-added/`
    );
  },

  getAuthUserEquityTransactions(params?: ListRequestParams) {
    return VestaApi.run<ListRequestResponse<EquityTransactionItem>>(
      {method: "GET", params},
      `${USER_API_ROOT}me/equity-transactions/`
    );
  },

  getAuthUserTransactions(params?: ListRequestParams) {
    return VestaApi.run<ListRequestResponse<TransactionItem>>(
      {method: "GET", params},
      `${USER_API_ROOT}me/transactions/`
    );
  },

  getAuthUserProperties(params?: RetrieveAuthUserPropertiesQueryParams) {
    return VestaApi.run<ListRequestResponse<Property>>(
      {method: "GET", params},
      `${USER_API_ROOT}me/properties/`
    );
  },

  getAuthUserPropertyCounts() {
    return VestaApi.run<PropertyCounts>(
      {method: "GET"},
      `${USER_API_ROOT}me/property-counts/`
    );
  }
};

export default userApi;
