/* eslint-disable no-magic-numbers */
function constructPaginationItems(
  {totalItemCount, perPage}: {totalItemCount: number; perPage: number},
  activePage: number
): number[] {
  const totalPage = Math.ceil(totalItemCount / perPage);

  if (totalPage <= 3) {
    return Array(totalPage)
      .fill(0)
      .map((_i, index) => index + 1);
  }

  let paginationItems: number[] = [];
  const firstTwoPages = [1, 2];
  const lastTwoPages = [totalPage - 1, totalPage];
  const neighbours = [activePage - 1, activePage, activePage + 1];

  if (activePage === firstTwoPages[0]) {
    paginationItems = [...firstTwoPages, NaN, ...lastTwoPages];
  } else if (activePage === totalPage) {
    paginationItems = [1, NaN, ...lastTwoPages];
  } else if (firstTwoPages.includes(activePage - 1)) {
    paginationItems = Array.from(new Set([1, ...neighbours, NaN, totalPage]));
  } else if (lastTwoPages.includes(activePage + 1)) {
    paginationItems = Array.from(new Set([1, NaN, ...neighbours, ...lastTwoPages]));
  } else {
    paginationItems = [1, NaN, ...neighbours, NaN, totalPage];
  }

  return paginationItems;
}

export {constructPaginationItems};
