import "./_bar-chart.scss";

import classNames from "classnames";
import {Spinner} from "@hipo/react-ui-toolkit";
import {ResponsiveBar} from "@nivo/bar";
import {linearGradientDef} from "@nivo/core";

import {DATE_FORMAT, MONTHS_IN_A_YEAR} from "../../core/util/date/dateConstants";
import {formatDate} from "../../core/util/date/dateUtils";
import {PropertyAnalysisZipHpiTimeSeriesHistoricalItem} from "../../listing/api/propertyAnalysisApiModels";
import {MEDIA_QUERY} from "../line-chart/utils/mediaQueryConstants";

export type BarChartData = Pick<
  PropertyAnalysisZipHpiTimeSeriesHistoricalItem,
  "month" | "hpi_value"
>;

interface BarChartProps {
  data: BarChartData[];
  shouldDisplaySpinner?: boolean;
  isDashboardPage?: boolean;
}

function BarChart({
  data,
  shouldDisplaySpinner = false,
  isDashboardPage = false
}: BarChartProps) {
  const responsiveOptions =
    window.innerWidth < MEDIA_QUERY.SMALL_MAX_WIDTH
      ? {
          fontSize: 10,
          pointSize: 5,
          marginLeft: 40,
          marginBottom: 70,
          marginRight: 10
        }
      : {
          fontSize: 12,
          pointSize: 10,
          marginLeft: 40,
          marginBottom: 70,
          marginRight: 30
        };

  return (
    <div
      className={classNames(
        "bar-chart-wrapper",
        `bar-chart-wrapper--${isDashboardPage && "dashboard"}`
      )}
    >
      {shouldDisplaySpinner ? (
        <Spinner customClassName={"bar-chart-wrapper__spinner"} />
      ) : (
        <ResponsiveBar
          data={data || []}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: responsiveOptions.fontSize,
                  fill: "#8B8C9D"
                }
              }
            }
          }}
          axisBottom={{
            tickSize: 0,
            tickRotation: calculateTickRotation(),
            tickPadding: 16,
            tickValues: generateTickValues(),

            format(value) {
              return formatDate(new Date(value), DATE_FORMAT.MONTH_YEAR_FORMAT);
            }
          }}
          // eslint-disable-next-line no-magic-numbers
          gridYValues={isDashboardPage ? 4 : 11}
          axisLeft={{
            tickValues: 6,
            tickSize: 0,
            tickPadding: 16
          }}
          keys={["hpi_value"]}
          indexBy={"month"}
          margin={{
            top: 10,
            right: responsiveOptions.marginRight,
            bottom: responsiveOptions.marginBottom,
            left: responsiveOptions.marginLeft
          }}
          padding={0.75}
          borderRadius={6}
          enableLabel={false}
          defs={[
            linearGradientDef("gradientBarChart", [
              {offset: 0, color: "#EEBC6D", opacity: 0.6},
              {offset: 100, color: "#DB6443"}
            ])
          ]}
          fill={[{match: "*", id: "gradientBarChart"}]}
          tooltip={({id, value, color}) => (
            <div
              className={"bar-chart-wrapper__tooltip"}
              style={{
                padding: 12
              }}
            >
              <p>{"Home Price Index "}</p>
              <strong>{value}</strong>
            </div>
          )}
        />
      )}
    </div>
  );

  // adjust x-axis labels rotation based on number of data to prevent squished labels
  /* eslint-disable no-magic-numbers */
  function calculateTickRotation(): number {
    if ((data && data.length > 80) || window.innerWidth < MEDIA_QUERY.SMALL_MAX_WIDTH) {
      return -90;
    } else if (data && data.length < 60 && data.length > 20) {
      return -45;
    }

    return 0;
  }
  /* eslint-enable no-magic-numbers */

  function generateTickValues() {
    let tickValues = data || [];

    if (
      // eslint-disable-next-line no-magic-numbers
      tickValues.length > 60 ||
      // eslint-disable-next-line no-magic-numbers
      (window.innerWidth < MEDIA_QUERY.SMALL_MAX_WIDTH && data.length > 30)
    ) {
      tickValues = data.filter((timeSeriesItem: BarChartData, index: number) => {
        return index % MONTHS_IN_A_YEAR === 0;
      });
    }

    return tickValues.map((timeSeriesItem: BarChartData) => timeSeriesItem.month);
  }
}

export default BarChart;
