const BASE = `/`;

const ROUTES = {
  BASE,
  HOME: BASE,
  DASHBOARD: `/`,
  LISTINGS: {
    ROOT: `/listings`,
    DETAIL: `/listings/:listing_id`,
    CREATE: `/listings/create`,
    EDIT: `/listings/:listing_id/edit`,
    MEDIA: `/listings/:listing_id/:media_id`
  },
  PORTFOLIO: {
    ROOT: `/portfolio`,
    DETAIL: "/portfolio/property/:property_id"
  },
  MARKETPLACE: {
    ROOT: `/marketplace`,
    LISTING_DETAIL: `/marketplace/listings/:listing_id`,
    LISTING_MEDIA: `/marketplace/listings/:listing_id/:media_id`
  },
  OFFERS: `/offers`,
  SETTINGS: `/settings`,
  AUTH: {
    LOGIN: `/auth/login`
  },
  ONBOARDING: {
    SIGNUP: `/onboarding/signup`,
    EMAIL_VERIFICATION: `/onboarding/email-verification`,
    ID_VERIFICATION: `/onboarding/id-verification`,
    ONBOARDING_TYPE: `/onboarding/onboarding-type`,
    KYC: `/onboarding/kyc`,
    WALLET_CREATION: `/onboarding/wallet-creation`,
    SUCCESS: `/onboarding/success`,
    INVESTOR: {
      SIGNUP: `/onboarding/investor/signup`
    }
  },
  RESET_PASSWORD: `/forgotten-password`,
  NEW_PASSWORD: `/forgotten-password/callback`
} as const;

export default ROUTES;
