export const TIME_SPAN_DROPDOWN_OPTIONS = [
  {id: "13", title: "1 year"},
  {id: "26", title: "2 years"},
  {id: "37", title: "3 years"},
  {id: "61", title: "5 years"},
  {id: "121", title: "10 years"},
  {id: "181", title: "15 years"},
  {id: "241", title: "20 years"},
  {id: "361", title: "30 years"},
  {id: "481", title: "40 years"},
  {id: "601", title: "50 years"},
  {id: "13F", title: "1 year forecast"},
  {id: "26F", title: "2 years forecast"},
  {id: "37F", title: "3 years forecast"}
];
