import "./_portfolio-property-card.scss";
import classNames from "classnames";
import {Tab, Spinner} from "@hipo/react-ui-toolkit";
import {useEffect} from "react";

import useAppContext from "../../../core/app/util/hook/useAppContext";
import TabHeaderContent from "../../../components/tab/header-content/TabHeaderContent";
import Card from "../../../components/card/Card";
import PortfolioPagePropertyList from "../list/PortfolioPagePropertyList";
import useAsyncProcess, {
  INITIAL_ASYNC_PROCESS_STATE
} from "../../../core/network/async-process/useAsyncProcess";
import userApi from "../../../user/api/userApi";
import {PropertyCounts} from "../../../property/api/propertyApiModels";
import AsyncContent, {
  AsyncContentStatus
} from "../../../components/async-content/AsyncContent";

interface PortfolioPropertyTabItem {
  id: "all" | "owned" | "invested";
  content: JSX.Element;
}

function PortfolioPropertyCard() {
  const {dispatchAppStateAction} = useAppContext();

  const {state, runAsyncProcess} = useAsyncProcess<PropertyCounts>({
    initialState: {
      ...INITIAL_ASYNC_PROCESS_STATE,
      data: {all_count: 0, invested_count: 0, owned_count: 0}
    },
    shouldResetDataWhenPending: false
  });

  useEffect(() => {
    (async () => {
      await runAsyncProcess(userApi.getAuthUserPropertyCounts());
    })();
  }, [dispatchAppStateAction, runAsyncProcess]);

  return (
    <Card
      customClassName={classNames(
        "portfolio-property-card",
        "portfolio-card--type--properties"
      )}
    >
      <AsyncContent requestStates={[state]} content={renderAsyncContent} />
    </Card>
  );

  function generateListingTabItems(): PortfolioPropertyTabItem[] {
    return [
      {
        id: "all",
        content: (
          <TabHeaderContent title={"All Properties"} value={state.data?.all_count} />
        )
      },
      {
        id: "owned",
        content: (
          <TabHeaderContent
            title={"Properties You Own"}
            value={state.data?.owned_count}
          />
        )
      },
      {
        id: "invested",
        content: (
          <TabHeaderContent
            title={"Properties You Invested In"}
            value={state.data?.invested_count}
          />
        )
      }
    ];
  }

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <></>;

    switch (status) {
      case "pending":
        node = (
          <div className={"portfolio-card--type--properties__loading-view-container"}>
            <Spinner />
          </div>
        );
        break;

      case "error":
        node = <div>{"An error occured"}</div>;
        break;

      case "success":
        node = (
          <Tab items={generateListingTabItems()}>
            {[
              <PortfolioPagePropertyList
                key={"all"}
                skeletonCount={state.data?.all_count}
              />,
              <PortfolioPagePropertyList
                key={"owned"}
                isInvested={false}
                skeletonCount={state.data?.owned_count}
              />,
              <PortfolioPagePropertyList
                key={"invested"}
                isInvested={true}
                skeletonCount={state.data?.invested_count}
              />
            ]}
          </Tab>
        );
        break;

      default:
        break;
    }

    return node;
  }
}

export default PortfolioPropertyCard;
