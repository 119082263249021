import "./_portfolio-property-list.scss";
import "../../../listing/page/list/_listings-page-property-list.scss";

import {useState, useEffect} from "react";

import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import Pagination from "../../../components/pagination/Pagination";
import userApi from "../../../user/api/userApi";
import {Property} from "../../../listing/api/listingApiModels";
import PortfolioPropertyLocationCard from "../location/PortfolioPropertyLocationCard";
import PropertyList from "../../../listing/property/list/PropertyList";

interface PortfolioPagePropertyListProps {
  isInvested?: boolean;
  skeletonCount?: number;
  shouldHideSoldAndListedEquityLabel?: boolean;
  shouldHideAvailableEquityLabel?: boolean;
  shouldHideOwnedEquityLabel?: boolean;
}

function PortfolioPagePropertyList({
  isInvested,
  skeletonCount,
  shouldHideAvailableEquityLabel = true,
  shouldHideOwnedEquityLabel = false,
  shouldHideSoldAndListedEquityLabel = true
}: PortfolioPagePropertyListProps) {
  const [activePage, setActivePage] = useState(1);

  const {state: propertiesRequestState, runAsyncProcess: propertiesRequestProcess} =
    useAsyncProcess<ListRequestResponse<Property>>();

  useEffect(() => {
    (async () => {
      await propertiesRequestProcess(
        userApi.getAuthUserProperties({
          // eslint-disable-next-line no-magic-numbers
          offset: (activePage - 1) * 5,
          invested: isInvested
        })
      );
    })();
  }, [propertiesRequestProcess, isInvested, activePage]);

  const properties = propertiesRequestState.data?.results;

  return (
    <>
      {propertiesRequestState.data?.count && propertiesRequestState.data?.count > 0 ? (
        <>
          <div className={"portfolio-page-property-list__wrapper"}>
            <PropertyList
              isLoading={propertiesRequestState.isRequestPending}
              properties={properties || []}
              skeletonCount={skeletonCount}
              shouldHideAvailableEquityLabel={shouldHideAvailableEquityLabel}
              shouldHideOwnedEquityLabel={shouldHideOwnedEquityLabel}
              shouldHideSoldAndListedEquityLabel={shouldHideSoldAndListedEquityLabel}
            />
          </div>
          <Pagination
            customClassName={"portfolio-page-property-list__pagination"}
            activePage={activePage}
            onPageChange={handlePageChange}
            totalItemCount={propertiesRequestState.data.count}
          />
        </>
      ) : null}
    </>
  );

  function handlePageChange(pageNumber: number) {
    setActivePage(pageNumber);
  }
}

export default PortfolioPagePropertyList;
