import {ReactComponent as EquityDividend} from "../../../../core/ui/icon/listing/Equity_Dividend_Icon_circle.svg";

import {formatNumber} from "../../../../core/util/number/numberUtils";
import "./_equity-dividend-info.scss";

interface equityDividend {
  equityDividend: number;
}

function EquityDividendInfo({equityDividend}: equityDividend) {
  return (
    <div className={"equity-dividend-info__dividend"}>
      <div className={"equity-dividend-info__dividend__container"}>
        <div className={"equity-dividend-info__dividend__container__distribution"}>
          <div
            className={"equity-dividend-info__dividend__container__distribution__icon"}
          >
            <EquityDividend width={18.2} height={18.2} />
          </div>
          <div
            className={"equity-dividend-info__dividend__container__distribution__text"}
          >
            <div
              className={
                "equity-dividend-info__dividend__container__distribution__percent"
              }
            >{`${formatNumber({
              maximumFractionDigits: 1
            })(equityDividend)}%`}</div>
            <div
              className={"equity-dividend-info__dividend__container__distribution__title"}
            >
              {"Annual Distribution"}
            </div>
          </div>
        </div>
      </div>
      {"Equity Dividend"}
    </div>
  );
}

export default EquityDividendInfo;
