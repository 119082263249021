/* eslint-disable no-magic-numbers */
import {FormatNumberOptions} from "./numberTypes";

function formatNumber(formatNumberOptions: FormatNumberOptions) {
  const {locale, shouldShortenLargeNumbers, ...otherOptions} = formatNumberOptions;
  const options = {
    style: "decimal",
    ...otherOptions
  };

  let numberFormatter: {
    format: (x: number | bigint) => string;
  };

  try {
    numberFormatter = new Intl.NumberFormat(
      locale || [navigator.language, "en-GB"],
      options
    );
  } catch (error) {
    numberFormatter = {
      format(x: number | bigint) {
        return x.toLocaleString(locale);
      }
    };
  }

  return (value: number) => {
    let formattedValue = "";

    if (!Object.is(value, NaN)) {
      /* eslint-disable no-magic-numbers */
      // 1000000000 -> 1B
      // 1000000 -> 1M
      // 100000 -> 100K
      // 10000 -> 10K
      // 1000 -> 1000
      if (shouldShortenLargeNumbers) {
        if (value >= Math.pow(10, 9)) {
          formattedValue = `${numberFormatter.format(value / Math.pow(10, 9))}B`;
        } else if (value >= Math.pow(10, 6)) {
          formattedValue = `${numberFormatter.format(value / Math.pow(10, 6))}M`;
        } else if (value >= Math.pow(10, 3)) {
          formattedValue = `${numberFormatter.format(value / Math.pow(10, 3))}K`;
        } else {
          formattedValue = numberFormatter.format(value);
        }
      } else {
        formattedValue = numberFormatter.format(value);
      }
      /* eslint-enable no-magic-numbers */
    }

    return formattedValue;
  };
}

function formatPrice(
  price: number,
  // eslint-disable-next-line no-magic-numbers
  maximumFractionDigits = 2,
  minimumFractionDigits = 0,
  shouldShortenLargeNumbers = false
) {
  return formatNumber({
    locale: "en-US",
    style: "currency",
    currency: "USD",
    maximumFractionDigits,
    minimumFractionDigits,
    shouldShortenLargeNumbers
  })(price);
}

function formatPercent(
  percent: number,
  // eslint-disable-next-line no-magic-numbers
  maximumFractionDigits = 2,
  minimumFractionDigits = 0,
  shouldShortenLargeNumbers = false
) {
  return formatNumber({
    locale: "en-US",
    style: "percent",
    maximumFractionDigits,
    minimumFractionDigits,
    shouldShortenLargeNumbers
  })(percent);
}

function formatAdaptivePercent(percent: number): string {
  const numberTen = 10;
  const numberHundred = 100;
  const percent10XMod = (numberTen * percent) % numberTen;
  const percent100XMod = (numberHundred * percent) % numberTen;

  if (percent100XMod === 0 && percent10XMod === 0) {
    return `${Math.round(percent)}%`;
  } else if (percent100XMod === 0) {
    return `${Math.round(percent * numberTen) / numberTen}%`;
  }
  // eslint-disable-next-line no-magic-numbers
  return `${Math.round(percent * numberHundred) / numberHundred}%`;
}

/**
 * Converts a string into a number.
 * @param {string} value A string to convert to number
 * @return {number} The value after converting the given value to a number.
 */
function stringToNumber(value: string): number | undefined {
  return Object.is(Number(value), NaN) ? undefined : Number(value);
}

const defaultNumberFormatter = formatNumber({
  locale: "en-US",
  maximumFractionDigits: 2
});

/**
 * Custom function that calculates the percent of a number.
 *
 * @param number percent The percent that you want to get.
 * @param number number The number that you want to calculate the percent of.
 * @returns {Number}
 */
function calculatePercentage(number: number, percent: number) {
  // eslint-disable-next-line no-magic-numbers
  return (percent / 100) * number;
}

export {
  formatNumber,
  stringToNumber,
  formatPrice,
  formatAdaptivePercent,
  formatPercent,
  defaultNumberFormatter,
  calculatePercentage
};
