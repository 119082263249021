import {ReactComponent as Dashboard} from "../../../core/ui/icon/dashboard.svg";
import {ReactComponent as Listings} from "../../../core/ui/icon/listings.svg";
import {ReactComponent as Portfolio} from "../../../core/ui/icon/portfolio.svg";
import {ReactComponent as Offers} from "../../../core/ui/icon/offers.svg";
import {ReactComponent as Marketplace} from "../../../core/ui/icon/marketplace.svg";
import {ReactComponent as Settings} from "../../../core/ui/icon/settings.svg";

import ROUTES from "../../route/routes";
import {AppNavigationItem} from "./navigationTypes";

const navigationItems: AppNavigationItem[] = [
  {
    id: "home",
    icon: <Dashboard />,
    content: "Dashboard",
    to: ROUTES.DASHBOARD
  },
  {
    id: "listings",
    icon: <Listings />,
    content: "Your Listings",
    to: ROUTES.LISTINGS.ROOT
  },
  {
    id: "portfolio",
    icon: <Portfolio />,
    content: "Your Portfolio",
    to: ROUTES.PORTFOLIO.ROOT
  },
  {
    id: "marketplace",
    icon: <Marketplace />,
    content: "Marketplace",
    to: ROUTES.MARKETPLACE.ROOT
  },
  {id: "offers", icon: <Offers />, content: "Offers", to: ROUTES.OFFERS},
  {id: "settings", icon: <Settings />, content: "Settings", to: ROUTES.SETTINGS}
];

export {navigationItems};
