import "./_equity-summary-info.scss";

function EquitySummaryInfo() {
  return (
    <div className={"equity-summary-info__summary"}>
      <div className={"equity-summary-info__summary__container"}>
        <div className={"equity-summary-info__summary__container__text"}>
          <div
            className={"equity-summary-info__summary__container__percent"}
          >{`100.000%`}</div>
          <div className={"equity-summary-info__summary__container__title"}>
            {"Total Equity to Date"}
          </div>
        </div>
        <div className={"equity-summary-info__summary__container__text"}>
          <div
            className={"equity-summary-info__summary__container__percent"}
          >{`0.000%`}</div>
          <div className={"equity-summary-info__summary__container__title"}>
            {"Equity Retained"}
          </div>
        </div>
        <div className={"equity-summary-info__summary__container__text"}>
          <div
            className={"equity-summary-info__summary__container__percent"}
          >{`0.000% (20 yrs.)`}</div>
          <div className={"equity-summary-info__summary__container__title"}>
            {"Equity Dividend Pool"}
          </div>
        </div>
      </div>
      {"Equity Summary"}
    </div>
  );
}

export default EquitySummaryInfo;
