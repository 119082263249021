function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toLocaleUpperCase() + string.slice(1);
}

function encodeText(text: string) {
  return new TextEncoder().encode(text);
}

function getFileNameFromURLPath(path: string) {
  return path.replace(/^.*(\\|\/|:)/, "");
}

export {capitalizeFirstLetter, encodeText, getFileNameFromURLPath};
