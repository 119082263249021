import {AuthUserProfileModel} from "../../../user/api/userApiModels";

function identifyUserOnHubspot(currentUser: AuthUserProfileModel) {
  // @ts-ignore window.HubSpotConversations comes from the hubspot chat widget
  if (window?.HubSpotConversations) {
    // @ts-ignore window._hsq comes from the hubspot chat widget
    // eslint-disable-next-line no-underscore-dangle
    const hsq = window._hsq || [];

    hsq.push([
      "identify",
      {
        email: currentUser.email,
        first_name: currentUser.first_name,
        last_name: currentUser.last_name
      }
    ]);
  }
}

export {identifyUserOnHubspot};
