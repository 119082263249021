import {ReactComponent as EmptyPortfolioIcon} from "../../../core/ui/icon/dashboard/empty-dashboard-portfolio.svg";

import "./_portfolio-analytics-card.scss";

import classNames from "classnames";
import {useEffect} from "react";
import {Dropdown, DropdownOption} from "@hipo/react-ui-toolkit";

import Card from "../../../components/card/Card";
import BarChart, {BarChartData} from "../../../components/bar-chart/BarChart";
import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import {
  PropertyAnalysis,
  PropertyAnalysisZipHpiTimeSeriesHistoricalItem
} from "../../../listing/api/propertyAnalysisApiModels";
import listingApi from "../../../listing/api/listingApi";
import {Property} from "../../../listing/api/listingApiModels";
import userApi from "../../../user/api/userApi";
import {formatDate} from "../../../core/util/date/dateUtils";
import {DATE_FORMAT} from "../../../core/util/date/dateConstants";
import useFormito from "../../../core/util/hook/formito/useFormito";
import {TIME_SPAN_DROPDOWN_OPTIONS} from "./dropdownConstants";

interface PortfolioAnalyticsCardProps {
  isDashboardPage?: boolean;
}

function PortfolioAnalyticsCard({isDashboardPage = false}: PortfolioAnalyticsCardProps) {
  const {state: propertiesRequestState, runAsyncProcess: propertiesRequestProcess} =
    useAsyncProcess<ListRequestResponse<Property>>();

  const {
    state: propertyAnalysisRequestState,
    runAsyncProcess: propertyAnalysisRequestProcess
  } = useAsyncProcess<PropertyAnalysis>();

  const {formitoState, dispatchFormitoAction} = useFormito({
    selectedProperty: null as null | DropdownOption,
    selectedTimeSpan: {id: "13", title: "1 year"} as DropdownOption,
    shouldDisplayError: false
  });

  // Fetch properties that owned by current user
  useEffect(() => {
    (async () => {
      const response = await propertiesRequestProcess(userApi.getAuthUserProperties());

      dispatchFormitoAction({
        type: "SET_FORM_VALUE",
        payload: {
          selectedProperty: {
            id: response.results[0].id,
            title: response.results[0].street_address
          }
        }
      });
    })();
  }, [dispatchFormitoAction, propertiesRequestProcess]);

  // Fetch the property analysis for the selected property
  useEffect(() => {
    (async () => {
      if (propertiesRequestState.data && formitoState.selectedProperty) {
        try {
          await propertyAnalysisRequestProcess(
            listingApi.getPropertyAnalysis(formitoState.selectedProperty.id)
          );
          dispatchFormitoAction({
            type: "SET_FORM_VALUE",
            payload: {
              shouldDisplayError: false
            }
          });
        } catch (error: any) {
          dispatchFormitoAction({
            type: "SET_FORM_VALUE",
            payload: {
              shouldDisplayError: true
            }
          });
        }
      }
    })();
  }, [
    dispatchFormitoAction,
    formitoState.selectedProperty,
    propertiesRequestState.data,
    propertyAnalysisRequestProcess
  ]);

  return (
    <Card
      headerContent={isDashboardPage ? "Your Portfolio" : "Analytics"}
      customClassName={classNames("portfolio-card", "portfolio-card--type--analytics")}
    >
      {propertiesRequestState.isRequestFetched && (
        <>
          <div
            className={classNames(
              "portfolio-card--type--analytics__dropdowns",
              isDashboardPage && "portfolio-card--type--analytics__dropdowns--dashboard"
            )}
          >
            <Dropdown
              customClassName={"portfolio-card--type--analytics__dropdowns__dropdown"}
              selectedOption={formitoState.selectedProperty}
              hasDeselectOption={false}
              canSelectAlreadySelected={true}
              onSelect={handleSelectDropdown("selectedProperty")}
              options={generatePropertyDropdownOptions()}
              role={"listbox"}
            />

            <Dropdown
              customClassName={"portfolio-card--type--analytics__dropdowns__dropdown"}
              selectedOption={formitoState.selectedTimeSpan}
              hasDeselectOption={false}
              canSelectAlreadySelected={true}
              onSelect={handleSelectDropdown("selectedTimeSpan")}
              options={TIME_SPAN_DROPDOWN_OPTIONS}
              role={"listbox"}
            />
          </div>
          {formitoState.shouldDisplayError && propertiesRequestState.data ? (
            <div className={"portfolio-card--type--analytics__empty-view"}>
              <EmptyPortfolioIcon
                className={"portfolio-card--type--analytics__empty-view__icon"}
              />
              <h2 className={"portfolio-card--type--analytics__empty-view__text"}>
                {"There is no data for this property."}
              </h2>
            </div>
          ) : (
            <BarChart
              shouldDisplaySpinner={
                propertiesRequestState.isRequestPending ||
                propertyAnalysisRequestState.isRequestPending
              }
              data={generateBarChartData()}
              isDashboardPage={isDashboardPage}
            />
          )}
        </>
      )}
    </Card>
  );

  function handleSelectDropdown(name: "selectedProperty" | "selectedTimeSpan") {
    return (option: DropdownOption | null) =>
      dispatchFormitoAction({
        type: "SET_FORM_VALUE",
        payload: {
          [name]: option
        }
      });
  }

  function generatePropertyDropdownOptions(): DropdownOption[] {
    return propertiesRequestState?.data?.results
      ? propertiesRequestState?.data?.results.map((item) => ({
          id: item.id,
          title: item.street_address
        }))
      : [];
  }

  function mergeDataSets() {
    let chartData: PropertyAnalysisZipHpiTimeSeriesHistoricalItem[] = [];

    if (propertiesRequestState.data) {
      if (propertyAnalysisRequestState.data?.zip_hpi_time_series_historical.time_series) {
        // add forecast data to historical data if it exists
        chartData = propertyAnalysisRequestState.data?.zip_hpi_ts_forecast?.time_series
          ? [
              ...propertyAnalysisRequestState.data?.zip_hpi_ts_forecast?.time_series.reverse(),
              ...propertyAnalysisRequestState.data?.zip_hpi_time_series_historical
                ?.time_series
            ]
          : propertyAnalysisRequestState.data?.zip_hpi_time_series_historical
              ?.time_series;
      }
    }
    return chartData;
  }

  function generateBarChartData() {
    const barChartData = mergeDataSets();
    let currentDateIndex;
    let arrangedData: BarChartData[] = [];

    if (barChartData) {
      const currentDate = new Date();

      currentDate.setMonth(0);
      currentDate.setDate(1);
      currentDateIndex = barChartData.findIndex(
        (data) =>
          data.month === formatDate(currentDate, DATE_FORMAT.YEAR_MONTH_DAY_FORMAT)
      );
      const reversedHistorical = barChartData
        .slice(0, Number(formitoState.selectedTimeSpan.id))
        .reverse();

      const historyEndIndex = currentDateIndex
        ? Number(formitoState.selectedTimeSpan.id) + currentDateIndex
        : Number(formitoState.selectedTimeSpan.id);

      const futureEndIndex = Number(formitoState.selectedTimeSpan.id.slice(0, -1));

      const desiredTimeSpanData =
        formitoState.selectedTimeSpan.id.includes("F") && currentDateIndex
          ? barChartData.slice(0, futureEndIndex || 0).reverse()
          : barChartData.slice(currentDateIndex, historyEndIndex || 0).reverse();

      arrangedData = propertyAnalysisRequestState.data?.zip_hpi_ts_forecast?.time_series
        ? desiredTimeSpanData
        : reversedHistorical;

      if (arrangedData.length === 0) {
        dispatchFormitoAction({
          type: "SET_FORM_VALUE",
          payload: {
            shouldDisplayError: true
          }
        });
      }
    }

    return barChartData && arrangedData;
  }
}

export default PortfolioAnalyticsCard;
