import {ReactComponent as NotificationIcon} from "../../../core/ui/icon/notification.svg";

import "./_user-profile-row.scss";

import {Button} from "@hipo/react-ui-toolkit";
import {useState, useRef} from "react";
import {useHistory} from "react-router-dom";

import useOnClickOutside from "../../../core/util/hook/useOnClickOutside";
import Avatar from "../../../components/avatar/Avatar";
import SearchInput from "../../../components/input/search/SearchInput";
import useAppContext from "../../../core/app/util/hook/useAppContext";
import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import userApi from "../../../user/api/userApi";
import ROUTES from "../../../core/route/routes";
import {resetWalletItemsOnWebStorage} from "../../../core/util/storage/webStorageUtils";

interface UserProfileRowProps {
  shouldDisplaySearch?: boolean;
}

function UserProfileRow({shouldDisplaySearch}: UserProfileRowProps) {
  const history = useHistory();

  const {
    appState: {currentUser}
  } = useAppContext();

  const [isProfileDropdownOpen, setProfileDropdownVisibility] = useState(false);

  const {state, runAsyncProcess} = useAsyncProcess();

  const userProfileDropdownRef = useRef(null);

  useOnClickOutside(userProfileDropdownRef, handleHideProfileDropdown);

  return (
    <div className={"user-profile-row"}>
      {shouldDisplaySearch && (
        <SearchInput
          customClassName={"user-profile-row__search-input"}
          name={"user-profile-row-search-input"}
          onQueryChange={handleSearch}
          placeholder={"Search on Vesta Equity"}
        />
      )}

      <Button
        customClassName={"user-profile-row__notification-button button--link"}
        onClick={handleNotificationClick}
      >
        <NotificationIcon />
      </Button>

      <Button
        customClassName={"user-profile-row__avatar-button button--link"}
        onClick={handleAvatarClick}
      >
        <Avatar alt={`${currentUser?.username}'s avatar`} src={currentUser?.avatar} />
      </Button>

      {isProfileDropdownOpen && (
        <div
          ref={userProfileDropdownRef}
          className={"user-profile-row__profile-dropdown"}
        >
          <div className={"user-profile-row__profile-dropdown__body animation--slide-in"}>
            <Button
              onClick={handleLogoutClick}
              customClassName={
                "button--link user-profile-row__profile-dropdown__body__logout-button"
              }
            >
              {"Logout"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  function handleSearch() {
    // TODO: Add functionality
  }

  async function handleLogoutClick() {
    await runAsyncProcess(userApi.logout());

    history.push(ROUTES.AUTH.LOGIN);

    resetWalletItemsOnWebStorage();

    window.location.reload();
  }

  function handleAvatarClick() {
    setProfileDropdownVisibility(!isProfileDropdownOpen);
  }

  function handleNotificationClick() {
    // TODO: Add functionality
  }

  function handleHideProfileDropdown() {
    setProfileDropdownVisibility(false);
  }
}

export default UserProfileRow;
