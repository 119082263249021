import webStorage from "../util/storage/webStorage";
import Fetcher from "./Fetcher";
import {fetchJSONMiddleware, stringifyJSON} from "./fetcherUtils";

const csrftoken = webStorage.cookie.getCookie<string | undefined>(
  process.env.REACT_APP_CSRF_TOKEN_NAME || "csrftoken"
);

const VestaApi = new Fetcher({
  baseUrl: process.env.REACT_APP_API_BASE_URL!,
  initOptions: {
    headers: {
      "Content-Type": "application/json",
      "x-csrftoken": csrftoken || ""
    },
    credentials: "include"
  },
  bodyParser: stringifyJSON,
  responseMiddlewares: [fetchJSONMiddleware]
});

// Using it for multipart/form-data requests
const VestaFormApi = new Fetcher({
  baseUrl: process.env.REACT_APP_API_BASE_URL!,
  initOptions: {
    headers: {
      "x-csrftoken": csrftoken || ""
    },
    credentials: "include"
  },
  responseMiddlewares: [fetchJSONMiddleware]
});

export {VestaFormApi};
export default VestaApi;
