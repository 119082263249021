import {Fragment, useEffect, useReducer} from "react";
import {useParams} from "react-router-dom";

import AsyncContent, {
  AsyncContentStatus,
  AsyncContentError
} from "../../../components/async-content/AsyncContent";
import RouteLoading from "../../../components/route-loading/RouteLoading";
import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import listingApi from "../../api/listingApi";
import CreateListingContext, {
  createListingStateReducer,
  initialCreateListingState
} from "./CreateListingContext";
import useQueryParams from "../../../core/util/hook/useQueryParams";

interface CreateListingContextProviderProps {
  children: React.ReactNode;
}

function CreateListingContextProvider({children}: CreateListingContextProviderProps) {
  const [createListingState, dispatchCreateListingStateAction] = useReducer(
    createListingStateReducer,
    initialCreateListingState
  );
  const {state: listingAsyncProcessState, runAsyncProcess: runListingAsyncProcess} =
    useAsyncProcess();

  const {listing_id} = useParams<{
    listing_id: string;
  }>();

  const {queryParams} = useQueryParams<{checkout_session_type?: string}>();

  useEffect(() => {
    (async function () {
      try {
        if (listing_id) {
          const listingResponse = await runListingAsyncProcess(
            listingApi.getlistingDetail(listing_id)
          );

          dispatchCreateListingStateAction({
            type: "SET_LISTING",
            payload: listingResponse
          });
        }

        if (queryParams.checkout_session_type === "appraisal-report") {
          dispatchCreateListingStateAction({
            type: "SET_STEP",
            payload: "inspection-appraisal"
          });
        } else if (queryParams.checkout_session_type === "fee") {
          dispatchCreateListingStateAction({
            type: "SET_STEP",
            payload: "listing-profile"
          });
        }

        // TODO: ASK cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
      } catch (error) {
        //  AsyncContent will handle error
      }
    })();
  }, [listing_id, runListingAsyncProcess, queryParams.checkout_session_type]);

  return (
    <CreateListingContext.Provider
      value={{
        createListingState,
        dispatchCreateListingStateAction
      }}
    >
      {listing_id ? (
        <AsyncContent
          content={renderAsyncContent}
          requestStates={[listingAsyncProcessState]}
        />
      ) : (
        children
      )}
    </CreateListingContext.Provider>
  );

  function renderAsyncContent(status: AsyncContentStatus, error: AsyncContentError) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <RouteLoading />;
        break;

      case "error":
        node = <Fragment>{"An error occured"}</Fragment>;
        break;

      case "success":
        node = <Fragment>{children}</Fragment>;
        break;

      default:
        break;
    }

    return node;
  }
}

export default CreateListingContextProvider;
