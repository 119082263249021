import {ReactComponent as SearchIcon} from "../../../core/ui/icon/search.svg";

import "./_search-input.scss";

import {TypeaheadInput, TypeaheadInputProps} from "@hipo/react-ui-toolkit";
import classNames from "classnames";

type SearchInputProps = TypeaheadInputProps;

function SearchInput({customClassName, ...otherProps}: SearchInputProps) {
  const className = classNames("search-input", customClassName);

  return (
    <TypeaheadInput
      customClassName={className}
      leftIcon={<SearchIcon />}
      {...otherProps}
    />
  );
}

export default SearchInput;
