import {WalletType} from "../../../wallet/utils/walletTypes";
import webStorage, {WEB_STORAGE_APP_DATA_KEYS} from "./webStorage";

function saveWalletTypeToWebStorage(walletType: WalletType) {
  webStorage.local.setItem(WEB_STORAGE_APP_DATA_KEYS.WALLET_TYPE, walletType);
}

function getWalletTypeFromWebStorage(): WalletType {
  const walletType = webStorage.local.getItem<WalletType>(
    WEB_STORAGE_APP_DATA_KEYS.WALLET_TYPE
  );

  return walletType;
}

function resetWalletItemsOnWebStorage() {
  return new Promise<undefined>((resolve, reject) => {
    try {
      webStorage.removeFromWebStorage(WEB_STORAGE_APP_DATA_KEYS.WALLET_TYPE);
      webStorage.removeFromWebStorage("walletconnect");

      resolve(undefined);
    } catch (error) {
      reject(error);
    }
  });
}

export {
  saveWalletTypeToWebStorage,
  getWalletTypeFromWebStorage,
  resetWalletItemsOnWebStorage
};
