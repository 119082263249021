import {ReactComponent as CrossIcon} from "../../../core/ui/icon/cross.svg";

import classNames from "classnames";
import React, {useReducer} from "react";
import {Button} from "@hipo/react-ui-toolkit";

import Modal from "../Modal";
import Card from "../../card/Card";
import ModalContext, {initialModalState, modalStateReducer} from "./ModalContext";

interface ModalContextProviderProps {
  children: React.ReactNode;
}

function ModalContextProvider({children}: ModalContextProviderProps) {
  const [modalState, dispatchModalStateAction] = useReducer(
    modalStateReducer,
    initialModalState
  );

  return (
    <ModalContext.Provider value={{modalState, dispatchModalStateAction}}>
      {children}

      {modalState.modalStack.map(
        ({id, children: modalChildren, customOverlayClassName, ...rest}) => (
          <React.Fragment key={id}>
            <Modal
              isOpen={true}
              onClose={handleCloseModal(id)}
              {...rest}
              customOverlayClassName={classNames(id, customOverlayClassName)}
            >
              <Card customClassName={"modal__card"}>
                <Button
                  customClassName={"modal__close-button button--link"}
                  onClick={handleCloseModal(id)}
                >
                  <CrossIcon />
                </Button>

                {modalChildren}
              </Card>
            </Modal>
          </React.Fragment>
        )
      )}
    </ModalContext.Provider>
  );

  function handleCloseModal(id: string) {
    return () =>
      dispatchModalStateAction({
        type: "CLOSE_MODAL",
        payload: {
          id
        }
      });
  }
}

export default ModalContextProvider;
