/* eslint-disable no-magic-numbers */
import {ReactComponent as DollarIcon} from "../../../core/ui/icon/dollar.svg";
import {ReactComponent as ExchangeIcon} from "../../../core/ui/icon/exchange.svg";
import {ReactComponent as PercentageIcon} from "../../../core/ui/icon/percentage.svg";

import "./_equity-share-marketplace-label.scss";

import classNames from "classnames";

import ProgressBar from "../../progress-bar/ProgressBar";
import {formatPrice, formatNumber} from "../../../core/util/number/numberUtils";
import {ListingPropertyCardView} from "../../../marketplace/listing/property/card/ListingPropertyCard";

export interface EquityShareLabelProps {
  value: string | number;
  percentage: string | number;
  type?: "listed" | "available" | "sold" | "owned";
  title?: string;
  shouldDisplayProgressBar?: boolean;
  size?: "medium" | "small" | "large" | "xsmall";
  isHidden?: boolean;
  customClassName?: string;
  titlePosition?: "left" | "center";
  shouldDisplayTitle?: boolean;
  view?: ListingPropertyCardView;
}

const iconSizeDecide = (size: string) => {
  switch (size) {
    case "large":
      return 18;
    case "medium":
      return 12;
    case "small":
      return 6;
    default:
      return 12;
  }
};

function EquityShareMarketplaceLabel({
  type,
  title = "",
  value,
  percentage,
  shouldDisplayProgressBar = true,
  customClassName,
  size = "medium",
  titlePosition = "center",
  shouldDisplayTitle = true,
  view
}: EquityShareLabelProps) {
  const equityShareLabelClassname = classNames(
    "equity-share-marketplace-label",
    `${view === "detail" && "equity-share-marketplace-label__detail"}`,
    customClassName,
    `equity-share-marketplace-label--size--${size}`,
    {
      "typography--paragraph": size === "medium",
      "typography--body": size === "small"
    }
  );

  const iconSize = iconSizeDecide(size);

  return (
    <div className={equityShareLabelClassname}>
      {shouldDisplayProgressBar && (
        <ProgressBar type={type} percentage={Number(percentage)} />
      )}
      <div
        className={`${
          titlePosition === "center"
            ? "equity-share-marketplace-label__value-div"
            : "equity-share-marketplace-label__value-div-col-exchange"
        }`}
      >
        <div
          className={classNames(
            "equity-share-marketplace-label__value  typography--bold",
            `${Number(value) === 0 && "equity-share-marketplace-label__zero-value"}`
          )}
        >
          <div className={"equity-share-marketplace-label__icon"}>
            <PercentageIcon width={iconSize} height={iconSize} />
          </div>
          <p
            className={`${`equity-share-marketplace-label__value--${size}`} "typography--bold"`}
          >{`${formatNumber({
            maximumFractionDigits: 2
          })(Number(percentage))}%`}</p>
        </div>
        {titlePosition === "center" ? (
          <div
            className={classNames(
              `${
                size === "large"
                  ? "equity-share-marketplace-label__title--large"
                  : "equity-share-marketplace-label__title"
              } typography--body`,
              `${
                Number(value) === 0
                  ? "equity-share-marketplace-label__zero-value"
                  : "equity-share-marketplace-label__with-value"
              }`
            )}
          >
            {generateEquityTitle()}
          </div>
        ) : (
          <div className={"equity-share-marketplace-label__icon__title-left"}>
            <ExchangeIcon width={iconSize} height={iconSize} />
          </div>
        )}
        <div
          className={classNames(
            "equity-share-marketplace-label__value  typography--bold",
            `${Number(value) === 0 && "equity-share-marketplace-label__zero-value"}`
          )}
        >
          <div className={"equity-share-marketplace-label__icon"}>
            <DollarIcon width={iconSize} height={iconSize} />
          </div>
          <p
            className={`${`equity-share-marketplace-label__value--${size}`} "typography--bold"`}
          >
            {formatPrice(Number(value), 0, 0, size === "small")}
          </p>
        </div>
      </div>
      {titlePosition === "left" && shouldDisplayTitle && (
        <div
          className={classNames(
            `${`equity-share-marketplace-label__title--${size}`}`,
            `${
              Number(value) === 0
                ? "equity-share-marketplace-label__zero-value"
                : "equity-share-marketplace-label__with-value"
            }`,
            `${`equity-share-marketplace-label__title--${size}__left`}`
          )}
        >
          {generateEquityTitle()}
        </div>
      )}
    </div>
  );

  function generateEquityTitle() {
    let newTitle = title;

    if (!newTitle) {
      switch (type) {
        case "available":
          newTitle = "Equity Available";
          break;

        case "listed":
          newTitle = "Equity Listed";
          break;

        case "sold":
          newTitle = "Equity Sold";
          break;

        case "owned":
          newTitle = "Equity Owned";
          break;

        default:
          break;
      }
    }

    return newTitle;
  }
}

export default EquityShareMarketplaceLabel;
