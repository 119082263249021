import {ReactComponent as EditIcon} from "../../../../core/ui/icon/edit.svg";
import {ReactComponent as CheckMark} from "../../../../core/ui/icon/check-mark-white.svg";
import {ReactComponent as CloseButton} from "../../../../core/ui/icon/cross.svg";

import {Button} from "@hipo/react-ui-toolkit";
import {useEffect, useState} from "react";

import BadgeCardStyle from "../../../../components/badge-card-style/BadgeCardStyle";
import {formatPrice} from "../../../../core/util/number/numberUtils";
import {getPropertyValuation} from "../../../util/listingUtils";
import {capitalizeFirstLetter} from "../../../../core/util/string/stringUtils";
import {formatDate} from "../../../../core/util/date/dateUtils";
import {DATE_FORMAT} from "../../../../core/util/date/dateConstants";
import {PropertyListItem, Property} from "../../../api/listingApiModels";
import {
  ListingPropertyCardEditProps,
  ListingPropertyCardView
} from "../ListingPropertyCard";
import useAppContext from "../../../../core/app/util/hook/useAppContext";
// SCSS import moved here to override badge styles
import "./_listing-property-card-header.scss";
import useAsyncProcess from "../../../../core/network/async-process/useAsyncProcess";
import listingApi from "../../../api/listingApi";
import {Offer} from "../../../../offer/api/offerApiModels";

type ListingPropertyCardHeaderListingProps = Pick<
  PropertyListItem,
  | "id"
  | "price"
  // | "buyback"
  // | "buyback_year"
  // | "buyback_yearly_appreciation_percentage"
  | "closing_datetime"
  | "status"
  | "is_owner"
  | "publication_datetime"
  | "buyback_triggered_datetime"
  | "total_visit_count"
  | "has_minimum_raise"
>;

interface ListingPropertyCardHeaderProps {
  property: Property;
  view: ListingPropertyCardView;
  listingProps?: ListingPropertyCardHeaderListingProps;
  editProps?: ListingPropertyCardEditProps;
}

// const buybackMessage = `The Buyback feature is an option that the homeowner can exercise at their discretion which allows them to repay
// the investment without selling their property. When initiated by the homeowner, all investors who had purchased equity in the listing are
//  repaid the greater of 1) the value of the equity at the time the Buyback is exercised or 2) the amount of the initial investment plus a
//  return on capital invested of 10% per year. The expiration date of the Buyback, indicated on the listing page, is 3 years from the date
//  of the first equity purchase on the listing. Please review the offering documentation for the listing for full and exact details.`;

function ListingPropertyCardHeader({
  property,
  view,
  listingProps,
  editProps
}: ListingPropertyCardHeaderProps) {
  const {
    appState: {currentUser}
  } = useAppContext();
  const {runAsyncProcess} = useAsyncProcess();
  const {runAsyncProcess: runListingAsyncProcess} = useAsyncProcess();
  const [buybackInitiated, setBuybackInitiated] = useState<boolean>(false);

  useEffect(() => {
    (async function () {
      if (listingProps) {
        await runListingAsyncProcess(listingApi.getListingOffers(listingProps?.id))
          .then((response) => acceptedOffersFilter(response.results))
          .catch(() => {
            return [];
          });

        if (listingProps.buyback_triggered_datetime) {
          setBuybackInitiated(true);
        }
      }
    })();
  }, [listingProps, runListingAsyncProcess]);

  // const [showBuybackMessage, setShowBuybackMessage] = useState<boolean>(false);

  // const handleToggleMessage = () => setShowBuybackMessage(!showBuybackMessage);

  return (
    <header className={"listing-property-card-header"}>
      <div
        className={"align-center--vertically listing-property-card-header__price--status"}
      >
        <div className={"listing-property-card-header__title"}>
          <h2 className={"listing-property-card-header__value"}>{getPrice()}</h2>
        </div>

        {view === "detail" && listingProps?.is_owner && (
          <BadgeCardStyle
            customClassName={"listing-property-card-header__status"}
            color={"teal"}
            type={"monochrome"}
          >
            {"Homeowner"}
          </BadgeCardStyle>
        )}
        {view !== "small" && view !== "action" && listingProps && (
          <>
            {listingProps.status !== "draft" && (
              <BadgeCardStyle
                customClassName={"listing-property-card-header__status"}
                color={listingProps.status === "active" ? "sunray" : "red"}
                type={"monochrome"}
              >
                {capitalizeFirstLetter(listingProps.status)}
              </BadgeCardStyle>
            )}
          </>
        )}
        {/* {view === "action" && (
          <div className={"listing-property-card-header__pending-badge"}>{"Pending"}</div>
        )} */}

        {listingProps?.status === "closed" && listingProps?.closing_datetime && (
          <p className={"listing-property-card-header__listing-date"}>
            {"Date Closed: "}
            <span>
              {formatDate(
                new Date(listingProps?.closing_datetime),
                DATE_FORMAT.DAY_MONTH_YEAR_FORMAT
              )}
            </span>
          </p>
        )}
      </div>

      <div className={"align-center--vertically listing-property-card-header__price"}>
        {/* {view === "detail" &&
          listingProps &&
          listingProps.is_owner &&
          listingProps.buyback && (
            <>
              {buybackInitiated ? (
                <label className={"listing-property-card-header__label"}>
                  {"Buyback initiated"}
                </label>
              ) : (
                <Button
                  color={"green"}
                  className={
                    "listing-property-card-header__initiate-buyback button button--small button--white"
                  }
                  onClick={handleInitiateBuybackSubmit}
                  type={"button"}
                >
                  {"Initiate Buyback"}
                </Button>
              )}
            </>
          )} */}

        {/* {view === "detail" && listingProps?.buyback && !listingProps.is_owner && (
          <div
            className={"listing-property-card-header__buyback__detail"}
            onMouseEnter={handleToggleMessage}
            onMouseLeave={handleToggleMessage}
          >
            <div
              className={"listing-property-card-header__buyback__detail__icon-wrapper"}
            >
              <CheckMark width={"12px"} height={"12px"} />
            </div>
            <div className={"listing-property-card-header__buyback__detail__description"}>
              {"Owner Buyback"}
            </div>
            {showBuybackMessage && (
              <div className={"listing-property-card-header__buyback__tooltip"}>
                <div
                  className={"listing-property-card-header__buyback__tooltip__container"}
                >
                  {buybackMessage}
                </div>
              </div>
            )}
          </div>
        )} */}

        {view !== "detail" && (
          <div className={"listing-property-card-header__visits"}>
            {/* {"Visits:"} */}
            {""}
            {/* <label className={"listing-property-card-header__visits__label"}>
              {listingProps?.total_visit_count ? listingProps.total_visit_count : 0}
            </label> */}
          </div>
        )}

        {/* {editProps?.shouldDisplayEditButton && (
          <Button
            onClick={editProps?.onEditClick}
            customClassName={"button--primary listing-property-card-header__edit-button"}
          >
            <EditIcon className={"listing-property-card-header__edit-button__icon"} />
            {"Edit"}
          </Button>
        )} */}
      </div>
    </header>
  );

  // async function handleInitiateBuybackSubmit() {
  //   try {
  //     await runAsyncProcess(listingApi.triggerBuyback(listingProps?.id as string));
  //     setBuybackInitiated(true);
  //   } catch (_error) {
  //     console.log("error", _error);
  //   }
  // }

  function acceptedOffersFilter(res: Offer[]) {
    return res.filter((value) => value.status === "accepted");
  }

  function getPrice() {
    const price = getPropertyValuation(property);

    return formatPrice(Number(price));
  }
}

export default ListingPropertyCardHeader;
