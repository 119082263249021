import {matchPath, useHistory} from "react-router";
import {useEffect} from "react";

import {AuthUserProfileModel} from "../../../api/userApiModels";
import ROUTES from "../../../../core/route/routes";
import useOnboardingContext from "./useOnboardingContext";

interface UseRedirectOnboardingStepProps {
  currentUser?: AuthUserProfileModel | null;
  shouldStart?: boolean;
}

function useRedirectOnboardingStep({
  currentUser,
  shouldStart
}: UseRedirectOnboardingStepProps) {
  const history = useHistory();
  const {onboardingState} = useOnboardingContext();
  const onboardingStep =
    currentUser?.next_required_onboarding_step || onboardingState.step;
  const onboardingType = currentUser?.onboarding_type || onboardingState.type;
  const isOnProtectedPages =
    location.pathname !== ROUTES.ONBOARDING.SIGNUP &&
    location.pathname !== ROUTES.ONBOARDING.INVESTOR.SIGNUP &&
    location.pathname !== ROUTES.AUTH.LOGIN &&
    location.pathname !== ROUTES.RESET_PASSWORD &&
    location.pathname !== ROUTES.NEW_PASSWORD &&
    !matchPath(location.pathname, {path: ROUTES.LISTINGS.DETAIL, exact: true}) &&
    !matchPath(location.pathname, {
      path: ROUTES.MARKETPLACE.LISTING_DETAIL,
      exact: true
    }) &&
    !matchPath(location.pathname, {
      path: ROUTES.MARKETPLACE.LISTING_MEDIA,
      exact: true
    }) &&
    !matchPath(location.pathname, {
      path: ROUTES.MARKETPLACE.ROOT,
      exact: true
    });

  const isOnPublicRoute =
    matchPath(location.pathname, {path: ROUTES.LISTINGS.DETAIL, exact: true}) ||
    matchPath(location.pathname, {
      path: ROUTES.MARKETPLACE.LISTING_DETAIL,
      exact: true
    }) ||
    matchPath(location.pathname, {
      path: ROUTES.MARKETPLACE.LISTING_MEDIA,
      exact: true
    }) ||
    matchPath(location.pathname, {
      path: ROUTES.MARKETPLACE.ROOT,
      exact: true
    });

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (shouldStart && !isOnPublicRoute) {
      if (
        onboardingStep === "email-verification" &&
        location.pathname !== ROUTES.ONBOARDING.EMAIL_VERIFICATION
      ) {
        history.replace(ROUTES.ONBOARDING.EMAIL_VERIFICATION);
      }

      if (
        (onboardingStep === "extra-personal-information" ||
          onboardingStep === "id-verification") &&
        location.pathname !== ROUTES.ONBOARDING.ID_VERIFICATION
      ) {
        history.replace(ROUTES.ONBOARDING.ID_VERIFICATION);
      }

      if (
        onboardingStep === "onboarding-type" &&
        location.pathname !== ROUTES.ONBOARDING.ONBOARDING_TYPE
      ) {
        history.replace(ROUTES.ONBOARDING.ONBOARDING_TYPE);
      }

      // KYC & AML step for homeowners can be enabled here
      // Just remove onboardingType === "investor" check
      if (
        (onboardingStep === "kyc-and-aml" ||
          onboardingStep === "accredited-investor-verification") &&
        onboardingType === "investor" &&
        location.pathname !== ROUTES.ONBOARDING.KYC
      ) {
        history.replace(ROUTES.ONBOARDING.KYC);
      }

      if (
        onboardingStep === "algorand-wallet" &&
        location.pathname !== ROUTES.ONBOARDING.WALLET_CREATION
      ) {
        history.replace(ROUTES.ONBOARDING.WALLET_CREATION);
      }

      if (!currentUser && isOnProtectedPages) {
        history.replace(ROUTES.ONBOARDING.SIGNUP);
      }

      if (
        currentUser &&
        !onboardingStep &&
        location.pathname.includes("onboarding") &&
        location.pathname !== ROUTES.ONBOARDING.SUCCESS
      ) {
        history.replace(ROUTES.BASE);
      }

      // TODO: Eventually this will be removed
      // Once we need to remove the blocker from onboarding, this block should be removed
      /*
      if (
        currentUser &&
        !onboardingStep &&
        location.pathname === ROUTES.BASE &&
        isOnProduction()
      ) {
        history.replace(ROUTES.ONBOARDING.SUCCESS);
      }

      */
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingStep, shouldStart]);
}

export default useRedirectOnboardingStep;
