import {ReactComponent as Arrow} from "../../core/ui/icon/arrow.svg";

import "./_carousel.scss";

import {Button, List, ListItem} from "@hipo/react-ui-toolkit";
import classNames from "classnames";
import {useState} from "react";

import Image from "../image/Image";

interface CarouselProps {
  images: string[];
  activeIndex?: number;
  customClassName?: string;
  ariaLabel?: string;
  size?: "small" | "medium";
  shouldDisplayControls?: boolean;
}

function Carousel({
  images,
  activeIndex = 0,
  customClassName,
  ariaLabel,
  size = "medium",
  shouldDisplayControls = true
}: CarouselProps) {
  const [activeImageIndex, setActiveImageIndex] = useState(activeIndex);

  return (
    <div
      role={"region"}
      className={classNames("carousel", `carousel--size--${size}`, customClassName)}
      aria-label={ariaLabel}
    >
      {images.length > 1 && shouldDisplayControls && (
        <Button
          customClassName={"carousel__control-button carousel__control-button--previous"}
          aria-label={"Go to previous image"}
          onClick={handleGoToPrevious}
        >
          <Arrow />
        </Button>
      )}

      <Image
        src={images[activeImageIndex]}
        customClassName={"carousel__image"}
        alt={"Carousel Item"}
        aria-label={`Image ${activeImageIndex + 1} of ${images.length}`}
      />

      {images.length > 1 && shouldDisplayControls && (
        <>
          <List
            items={Array.from({length: images.length})}
            customClassName={"carousel__indicator-list"}
          >
            {(item, _testid, index) => (
              <ListItem
                key={`carousel__indicator-list-item-${item}`}
                customClassName={"carousel__indicator-list-item"}
              >
                <Button
                  onClick={handleGoToImage(index!)}
                  aria-current={index === activeImageIndex}
                  aria-label={
                    index === activeImageIndex
                      ? "Active image"
                      : `Go to image ${index! + 1}`
                  }
                  customClassName={classNames("carousel__indicator-list-item__button", {
                    "carousel__indicator-list-item__button--is-active":
                      index === activeImageIndex
                  })}
                />
              </ListItem>
            )}
          </List>

          <Button
            customClassName={"carousel__control-button carousel__control-button--next"}
            aria-label={"Go to next image"}
            onClick={handleGoToNext}
          >
            <Arrow className={"carousel__arrow-icon--next"} />
          </Button>
        </>
      )}
    </div>
  );

  function handleGoToPrevious() {
    let prevIndex = activeImageIndex - 1;

    if (!images[prevIndex]) {
      prevIndex = images.length - 1;
    }

    setActiveImageIndex(prevIndex);
  }

  function handleGoToNext() {
    let nextIndex = activeImageIndex + 1;

    if (!images[nextIndex]) {
      nextIndex = 0;
    }

    setActiveImageIndex(nextIndex);
  }

  function handleGoToImage(index: number) {
    return () => {
      setActiveImageIndex(index);
    };
  }
}

export default Carousel;
