import "./_card.scss";

import classNames from "classnames";

export interface CardProps {
  children: React.ReactNode;
  headerContent?: React.ReactNode;
  customClassName?: string;
}

function Card({children, headerContent, customClassName}: CardProps) {
  return (
    <div className={classNames("card", customClassName)}>
      {headerContent && <header className={"card__header"}>{headerContent}</header>}

      {children}
    </div>
  );
}

export default Card;
