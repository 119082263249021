import "./_property-list.scss";

import {Button, List, ListItem} from "@hipo/react-ui-toolkit";
import classNames from "classnames";
import {useHistory, generatePath} from "react-router-dom";

import ListingPropertyCard, {ListingPropertyCardView} from "../card/ListingPropertyCard";
import {Property, PropertyListItem} from "../../api/listingApiModels";
import ROUTES from "../../../core/route/routes";
import PropertySkeletonList from "./skeleton/PropertySkeletonList";
import {isPropertyListItem} from "../../util/listingUtils";

export type PropertyListView =
  | "your-listings"
  | "recommended"
  | "dashboard"
  | "marketplace";

interface PropertyListProps {
  properties: (PropertyListItem | Property)[];
  isLoading: boolean;
  view?: PropertyListView;
  customClassName?: string;
  skeletonCount?: number;
  shouldHideSoldAndListedEquityLabel?: boolean;
  shouldHideAvailableEquityLabel?: boolean;
  shouldHideOwnedEquityLabel?: boolean;
}

function PropertyList({
  properties,
  view = "your-listings",
  customClassName,
  isLoading,
  skeletonCount,
  shouldHideAvailableEquityLabel,
  shouldHideOwnedEquityLabel,
  shouldHideSoldAndListedEquityLabel
}: PropertyListProps) {
  const history = useHistory();

  const listClassName = classNames(
    "property-list",
    `property-list--view--${view}`,
    customClassName
  );

  return (
    <List
      items={properties || []}
      placeholderProps={{
        placeholder: (
          <PropertySkeletonList
            size={view === "your-listings" ? "large" : "small"}
            customClassName={listClassName}
            count={skeletonCount}
          />
        ),
        shouldDisplayPlaceholder: isLoading
      }}
      customClassName={listClassName}
    >
      {(propertyListItem) => (
        <ListItem
          clickableListItemProps={{
            onClick: handleGoToDetailPage(propertyListItem)
          }}
          customClassName={"property-list__list-item"}
        >
          <ListingPropertyCard
            property={
              isPropertyListItem(propertyListItem)
                ? propertyListItem.property
                : propertyListItem
            }
            listingProps={
              isPropertyListItem(propertyListItem) ? {...propertyListItem} : undefined
            }
            view={generateListingPropertyCardView(view)}
            shouldHideAvailableEquityLabel={shouldHideAvailableEquityLabel}
            shouldHideOwnedEquityLabel={shouldHideOwnedEquityLabel}
            shouldHideSoldAndListedEquityLabel={shouldHideSoldAndListedEquityLabel}
          />

          {/* {!isPropertyListItem(propertyListItem) && (
            <Button
              customClassName={"button--primary property-list__list-item__sell-button"}
            >
              {"Sell Equity"}
            </Button>
          )} */}
        </ListItem>
      )}
    </List>
  );

  function generateListingPropertyCardView(
    listView: PropertyListView
  ): ListingPropertyCardView {
    let cardView = "small" as ListingPropertyCardView;

    switch (listView) {
      case "your-listings":
        cardView = "new-home-owners";
        break;

      default:
        break;
    }

    return cardView;
  }

  function handleGoToDetailPage(listing: Property | PropertyListItem) {
    return () => {
      let path = "";

      if (isPropertyListItem(listing)) {
        path = generatePath(ROUTES.LISTINGS.DETAIL, {
          listing_id: listing.id
        });
      } else {
        path = generatePath(ROUTES.PORTFOLIO.DETAIL, {
          property_id: listing.id
        });
      }

      history.push(path, {
        from: history.location.pathname + history.location.search
      });
    };
  }
}

export default PropertyList;
