import CarouselPlaceholder from "../../../core/ui/assets/carousel-placeholder.png";
import {ReactComponent as ArrowRightIcon} from "../../../core/ui/icon/arrow-right.svg";
import {ReactComponent as OutgoingTransferIcon} from "../../../core/ui/icon/dashboard/transactions/outgoing-transfer.svg";

import "./_portfolio-recent-activity.scss";
import classNames from "classnames";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {List, ListItem, Spinner, Button} from "@hipo/react-ui-toolkit";

import {generatePrismUrl} from "../../../core/util/image/prism/prismUtils";
import ListingPropertyCardEquityList from "../../../listing/property/card/equity/list/ListingPropertyCardEquityList";
import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import Card from "../../../components/card/Card";
import {EquityTransactionItem} from "../../../transaction/api/transactionApiModels";
import {renderTransactionTypeText} from "../../../transaction/utils/transactionUtils";
import AsyncContent, {
  AsyncContentStatus
} from "../../../components/async-content/AsyncContent";
import ROUTES from "../../../core/route/routes";
import userApi from "../../../user/api/userApi";
import {formatDateAsDaysAgo} from "../../../core/util/date/dateUtils";
import Image from "../../../components/image/Image";

function PortfolioRecentActivityCard() {
  const history = useHistory();

  const {state: transactionRequestState, runAsyncProcess: transactionsRequestProcess} =
    useAsyncProcess<ListRequestResponse<EquityTransactionItem>>();

  useEffect(() => {
    (async () => {
      await transactionsRequestProcess(userApi.getAuthUserEquityTransactions());
    })();
  }, [transactionsRequestProcess]);

  return (
    <Card
      headerContent={"Recent Activities"}
      customClassName={classNames(
        "portfolio-card",
        "portfolio-card--type--recent-activity"
      )}
    >
      <AsyncContent
        requestStates={[transactionRequestState]}
        content={renderAsyncContent}
      />
    </Card>
  );

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <div />;

    switch (status) {
      case "pending":
        node = (
          <div
            className={"portfolio-card--type--recent-activity__loading-view-container"}
          >
            <Spinner />
          </div>
        );
        break;

      case "error":
        node = <div>{"An error occured"}</div>;
        break;

      case "success":
        node = (
          <div className={"portfolio-card--type--recent-activity__content"}>
            <header className={"portfolio-card--type--recent-activity__content__header"}>
              <span
                className={
                  "portfolio-card--type--recent-activity__content__header__property-title"
                }
              >
                {"Property"}
              </span>
              <span
                className={
                  "portfolio-card--type--recent-activity__content__header__equity-title"
                }
              >
                {"Transaction"}
              </span>
              <span
                className={
                  "portfolio-card--type--recent-activity__content__header__date-title"
                }
              >
                {"Date"}
              </span>
            </header>

            <List
              emptyStateProps={{
                emptyState: "",
                shouldDisplayEmptyState:
                  transactionRequestState.data?.results.length === 0
              }}
              items={
                transactionRequestState.data?.results
                  .filter((transaction) => transaction.amount_in_usd)
                  // eslint-disable-next-line no-magic-numbers
                  .slice(0, 4) || []
              }
              customClassName={"portfolio-card--type--recent-activity__content__list"}
            >
              {(transaction) => (
                <ListItem
                  customClassName={
                    "portfolio-card--type--recent-activity__content__list__item"
                  }
                >
                  <div
                    className={
                      "portfolio-card--type--recent-activity__content__list__item__property"
                    }
                  >
                    {transaction.action === "transfer-in" ? (
                      <OutgoingTransferIcon />
                    ) : (
                      <Image
                        customClassName={
                          "portfolio-card--type--recent-activity__content__list__item__property__img"
                        }
                        src={
                          transaction.property.media.length &&
                          transaction.property.media[0].image
                            ? generatePrismUrl({width: 468})(
                                transaction.property.media[0].image
                              )
                            : CarouselPlaceholder
                        }
                        alt={"property"}
                      />
                    )}
                    <div
                      className={
                        "portfolio-card--type--recent-activity__content__list__item__property__address "
                      }
                    >
                      {transaction.action === "transfer-in"
                        ? "Tokenization"
                        : renderTransactionTypeText(transaction.action)}
                      <br />
                      <span
                        className={
                          "portfolio-card--type--recent-activity__content__list__item__property__address__transaction-type "
                        }
                      >
                        {transaction.property.street_address}
                      </span>
                    </div>
                  </div>
                  <ListingPropertyCardEquityList
                    transactionLabel={true}
                    transactionProps={transaction}
                  />
                  <span
                    className={
                      "portfolio-card--type--recent-activity__content__list__item__date typography--body--bold"
                    }
                  >
                    {formatDateAsDaysAgo(new Date(transaction.creation_datetime))}
                  </span>
                </ListItem>
              )}
            </List>

            <Button
              onClick={handleGoToTransactions}
              customClassName={
                "button--link portfolio-card--type--recent-activity__content__view-transactions-button"
              }
            >
              {"View All Transactions"}

              <ArrowRightIcon
                className={
                  "portfolio-card--type--recent-activity__content__view-transactions-button__icon"
                }
              />
            </Button>
          </div>
        );
        break;

      default:
        break;
    }

    return node;
  }

  function handleGoToTransactions() {
    history.push(`${ROUTES.SETTINGS}?type=wallet`);
  }
}

export default PortfolioRecentActivityCard;
