import "@hipo/react-ui-toolkit/dist/main.css";
import "react-datepicker/dist/react-datepicker.css";

import "./core/ui/style/reference/_color.scss";
import "./core/ui/style/override/_override.scss";
import "./core/ui/style/typography/_typography.scss";

import "./core/ui/style/util/_grid.scss";
import "./core/ui/style/util/_helper.scss";

import "./core/ui/style/component/_button.scss";
import "./core/ui/style/component/_input.scss";
import "./core/ui/style/component/_checkbox-input.scss";
import "./core/ui/style/component/_radio-input.scss";
import "./core/ui/style/component/_dropdown.scss";
import "./core/ui/style/component/_form-field.scss";
import "./core/ui/style/component/_toggle.scss";
import "./core/ui/style/component/_tab.scss";
import "./core/ui/style/component/_toast.scss";

import "./core/ui/style/_state-hooks.scss";

import "./core/ui/style/util/animation/_slide-in.scss";
import "./core/ui/style/util/animation/_pop.scss";
import "./core/ui/style/util/animation/_pulse.scss";

import ReactDOM from "react-dom";

import App from "./core/app/App";
import reportWebVitals from "./core/reportWebVitals";
import {initSentry} from "./core/integrations/sentry/sentry";

initSentry();

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
