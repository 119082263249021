import "./_image.scss";

import React, {useMemo, useState} from "react";
import classNames from "classnames";
import {Spinner} from "@hipo/react-ui-toolkit";

type ImageProps = React.DetailedHTMLProps<
  Omit<React.ImgHTMLAttributes<HTMLImageElement>, "className">,
  HTMLImageElement
> & {
  customClassName?: string;
};

function Image({customClassName, alt, ...imgProps}: ImageProps) {
  const [shouldDisplayPlaceholder, setPlaceholderVisibility] = useState(true);
  const [shouldDisplayError, setErrorVisibility] = useState(false);

  useMemo(() => {
    setPlaceholderVisibility(true);
  }, []);

  return (
    <div className={classNames("image", customClassName)}>
      {shouldDisplayPlaceholder && !shouldDisplayError && (
        <div className={"image__placeholder"}>
          <Spinner />
        </div>
      )}

      {shouldDisplayError && <div className={"image__placeholder"}>{alt}</div>}

      {!shouldDisplayError && (
        <img
          alt={alt}
          onLoad={handleHidePlaceholder}
          hidden={shouldDisplayPlaceholder}
          onError={handleDisplayError}
          {...imgProps}
          className={"image__img"}
        />
      )}
    </div>
  );

  function handleHidePlaceholder() {
    setPlaceholderVisibility(false);
  }

  function handleDisplayError() {
    handleHidePlaceholder();
    setErrorVisibility(true);
  }
}

export default Image;
