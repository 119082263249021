import "./_footer-links.scss";

import {List, ListItem} from "@hipo/react-ui-toolkit";

import {footerInternalLinks, footerSocialLinks} from "./utils/footerConstants";
import {FooterLinkItem} from "./utils/footerTypes";

interface FooterLinksProps {
  type: "social" | "internal";
}

function FooterLinks({type}: FooterLinksProps) {
  const linkItems: FooterLinkItem[] =
    type === "internal" ? footerInternalLinks : footerSocialLinks;

  return (
    <List
      items={linkItems}
      customClassName={`footer-links__list footer-links--type--${type}`}
    >
      {(linkItem) => (
        <ListItem customClassName={"footer-links__list-item"}>
          <a href={linkItem.href} target={"_blank"} rel={"noreferrer noopenner"}>
            {linkItem.icon || linkItem.title}
          </a>
        </ListItem>
      )}
    </List>
  );
}

export default FooterLinks;
