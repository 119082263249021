import "./_listing-property-card-equity-list.scss";

import {List, ListItem} from "@hipo/react-ui-toolkit";
import classNames from "classnames";

import EquityShareLabel, {
  EquityShareLabelProps
} from "../../../../../components/label/equity-share/EquityShareLabel";
import {PropertyListItem, Property} from "../../../../api/listingApiModels";
import {ListingPropertyCardView} from "../../ListingPropertyCard";
import {
  defaultNumberFormatter,
  calculatePercentage
} from "../../../../../core/util/number/numberUtils";
import {EquityTransactionItem} from "../../../../../transaction/api/transactionApiModels";
import {Offer} from "../../../../../offer/api/offerApiModels";
import EquityShareMarketplaceLabel from "../../../../../components/label/equity-share-marketplace/EquityShareMarketplaceLabel";

type OfferStatusType = "listed" | "sold" | "owned" | "available" | undefined;
type EquitySizeType = "large" | "medium" | "small" | "xsmall";
interface ListingPropertyCardEquityListProps {
  listingProps?: Pick<
    PropertyListItem,
    | "price"
    | "listed_equity_percentage"
    | "sold_equity_percentage"
    | "property"
    | "present_value"
  >;
  view?: ListingPropertyCardView;
  transactionProps?: EquityTransactionItem;
  property?: Property;
  shouldHideSoldAndListedEquityLabel?: boolean;
  shouldHideAvailableEquityLabel?: boolean;
  shouldHideOwnedEquityLabel?: boolean;
  transactionLabel?: boolean;
  offerStatus?: OfferStatusType;
  shouldDisplayProgressBar?: boolean;
  shouldDisplayTitle?: boolean;
  customClassName?: string;
  titlePosition?: "center" | "left";
  size?: EquitySizeType;
}

function ListingPropertyCardEquityList({
  listingProps,
  view = "small",
  transactionProps,
  property,
  shouldHideAvailableEquityLabel = true,
  shouldHideOwnedEquityLabel = true,
  shouldHideSoldAndListedEquityLabel = false,
  transactionLabel = false,
  offerStatus,
  shouldDisplayProgressBar = false,
  shouldDisplayTitle = true,
  customClassName,
  titlePosition = "center",
  size = "large"
}: ListingPropertyCardEquityListProps) {
  return (
    <List
      customClassName={classNames(
        "listing-property-card-equity-list",
        `listing-property-card-equity-list--view--${view}`,
        `${view !== "new-home-owners" && "grid--col--3"}`
      )}
      items={generateEquityListItems(offerStatus)}
    >
      {(listItem) => (
        <ListItem
          customClassName={`${
            view === "detail" && "listing-property-card-equity-list--item"
          }`}
        >
          {renderEquityByView(listItem)}
        </ListItem>
      )}
    </List>
  );

  function renderEquityByView(listItem: EquityShareLabelProps) {
    switch (view) {
      case "detail":
        return (
          <EquityShareMarketplaceLabel
            size={size}
            shouldDisplayProgressBar={shouldDisplayProgressBar}
            shouldDisplayTitle={shouldDisplayTitle}
            titlePosition={titlePosition}
            view={view}
            {...listItem}
          />
        );
      case "new-home-owners":
        return (
          <EquityShareLabel
            shouldDisplayProgressBar={shouldDisplayProgressBar}
            size={"xsmall"}
            customClassName={customClassName}
            shouldDisplayTitle={shouldDisplayTitle}
            {...listItem}
          />
        );
      default:
        return (
          <EquityShareLabel
            shouldDisplayProgressBar={shouldDisplayProgressBar}
            size={view === "small" ? "small" : "large"}
            customClassName={customClassName}
            shouldDisplayTitle={shouldDisplayTitle}
            {...listItem}
          />
        );
    }
  }

  function getListedEquityPercentage() {
    if (transactionProps) {
      return transactionProps.equity_percentage;
    } else if (listingProps) {
      return listingProps?.listed_equity_percentage;
      // TODO: to get percentage type with param combine function with getSoldEquityPercentage
    }

    return property?.owned_equity_percentage;
  }

  function getSoldEquityValue() {
    if (listingProps) {
      return listingProps.present_value || 0;
    }
    return 0;
  }

  function getSoldEquityPercentage() {
    if (transactionProps) {
      return transactionProps.equity_percentage;
    } else if (listingProps) {
      return listingProps?.sold_equity_percentage;
    }

    return property?.owned_equity_percentage;
  }

  function getOwnedEquityPercentage() {
    if (transactionProps) {
      return transactionProps.equity_percentage;
    } else if (listingProps) {
      return listingProps?.property?.owned_equity_percentage;
    }

    return Number(property?.owned_equity_percentage);
  }

  function getAmount() {
    if (transactionProps) {
      return transactionProps?.amount_in_usd;
    } else if (listingProps) {
      return listingProps?.price;
    }

    return (
      property?.adjusted_appraised_value || property?.appraisal_report?.property_value
    );
  }

  function generateEquityListItems(status: OfferStatusType): EquityShareLabelProps[] {
    const listedEquityPercentage = getListedEquityPercentage();

    const listedEquityValue = calculatePercentage(
      Number(getAmount()),
      Number(listedEquityPercentage)
    );

    const soldEquityPercentage = getSoldEquityPercentage();

    const soldEquityValue = getSoldEquityValue();

    const ownedEquityPercentage = getOwnedEquityPercentage();

    const ownedEquityValue = calculatePercentage(
      Number(getAmount()),
      Number(ownedEquityPercentage)
    );

    const items: EquityShareLabelProps[] = [
      {
        type: "listed",
        value: String(listedEquityValue),
        percentage: defaultNumberFormatter(Number(listedEquityPercentage)),
        isHidden: shouldHideSoldAndListedEquityLabel
      },
      {
        type: "sold",
        value: transactionLabel ? getAmount() || " " : String(soldEquityValue),
        percentage: transactionLabel
          ? String(soldEquityPercentage)
          : defaultNumberFormatter(Number(soldEquityPercentage)),
        isHidden: shouldHideSoldAndListedEquityLabel
      },
      {
        type: "owned",
        value: String(ownedEquityValue),
        percentage: defaultNumberFormatter(Number(ownedEquityPercentage)),
        isHidden: shouldHideOwnedEquityLabel
      }
    ];

    items.splice(1, 0, {
      type: "available",
      // eslint-disable-next-line no-magic-numbers
      value: String(Number(items[0].value) - Number(items[1].value)),
      percentage: defaultNumberFormatter(
        Number(items[0].percentage) - Number(items[1].percentage)
      ),
      isHidden: shouldHideAvailableEquityLabel
    });

    if (status !== undefined) {
      return items.filter((item) => item.type === status);
    }

    return items.filter((item) => !item.isHidden);
  }
}

export default ListingPropertyCardEquityList;
