import {ReactComponent as BedroomIcon} from "../../../../core/ui/icon/listing/bedroom.svg";
import {ReactComponent as BathroomIcon} from "../../../../core/ui/icon/listing/bathroom.svg";
import {ReactComponent as CarParkingIcon} from "../../../../core/ui/icon/listing/carparking.svg";
import {ReactComponent as FloorIcon} from "../../../../core/ui/icon/listing/floor.svg";
import {ReactComponent as SqftIcon} from "../../../../core/ui/icon/listing/sqft.svg";
import {ReactComponent as ResidentailIcon} from "../../../../core/ui/icon/listing/home.svg";
import {ReactComponent as SingleFamilyIcon} from "../../../../core/ui/icon/listing/singleFam.svg";
import {ReactComponent as BuiltIcon} from "../../../../core/ui/icon/listing/buildDate.svg";

import "./_listing-property-card-footer.scss";

import {Button, List, ListItem} from "@hipo/react-ui-toolkit";

import ListingPropertyDetailItem from "../../detail-item/ListingPropertyDetailItem";
import {Property, PropertyListItem} from "../../../api/listingApiModels";
import generatePropertyFooterItems from "./generatePropertyFooterItems";

interface ListingPropertyCardFooterProps {
  property: Property;
  listingProps?: Pick<PropertyListItem, "status">;
}

function ListingPropertyCardFooter({
  property,
  listingProps
}: ListingPropertyCardFooterProps) {
  const propertyDetailListItems = generatePropertyFooterItems({property});

  return propertyDetailListItems.length ? (
    <footer className={"listing-property-card-footer"}>
      <List
        items={propertyDetailListItems}
        customClassName={"listing-property-card-footer__list"}
      >
        {(propertyDetail) => (
          <ListItem customClassName={"listing-property-card-footer__list-item"}>
            <ListingPropertyDetailItem {...propertyDetail} />
          </ListItem>
        )}
      </List>

      {listingProps?.status === "closed" && (
        <Button
          customClassName={
            "listing-property-card-footer__resell-button button--primary typography--caption"
          }
        >
          {"Resell Equity"}
        </Button>
      )}
    </footer>
  ) : null;
}

export default ListingPropertyCardFooter;
