import {ReactComponent as BookmarkIcon} from "../../../../core/ui/icon/bookmark.svg";
import {ReactComponent as ShareIcon} from "../../../../core/ui/icon/share.svg";

import "./_listing-property-card-action-list.scss";

import {List, ListItem} from "@hipo/react-ui-toolkit";
import React from "react";

import {PropertyListItem} from "../../../api/listingApiModels";
import useAsyncProcess from "../../../../core/network/async-process/useAsyncProcess";
import listingApi from "../../../api/listingApi";

interface ListingPropertyCardActionListItem {
  id: string;
  icon: JSX.Element;
  onClick: (event: React.MouseEvent<HTMLLIElement>) => void;
  title?: string;
  isShown?: boolean;
}

interface ListingPropertyCardActionListProps {
  listingProps: Pick<PropertyListItem, "id">;
  shouldDisplayBookmark?: boolean;
  shouldDisplayShare?: boolean;
}

function ListingPropertyCardActionList({
  listingProps,
  shouldDisplayBookmark = true,
  shouldDisplayShare = true
}: ListingPropertyCardActionListProps) {
  const listingPropertyCardActionListItems: ListingPropertyCardActionListItem[] = [
    {
      id: "bookmark",
      icon: <BookmarkIcon />,
      onClick: handleBookmarkClick,
      isShown: shouldDisplayBookmark
    },
    {
      id: "share",
      icon: <ShareIcon className={"listing-property-card-action-list__share-icon"} />,
      onClick: handleShareClick,
      title: "Share",
      isShown: shouldDisplayShare
    }
  ];

  const {runAsyncProcess} = useAsyncProcess();

  return (
    <List
      customClassName={"listing-property-card-action-list"}
      items={listingPropertyCardActionListItems.filter((item) => item.isShown)}
    >
      {(actionItem) => (
        <ListItem
          customClassName={"listing-property-card-action-list__list-item"}
          clickableListItemProps={{onClick: actionItem.onClick}}
        >
          {actionItem.icon}

          {actionItem.title}
        </ListItem>
      )}
    </List>
  );

  async function handleBookmarkClick(event: React.MouseEvent<HTMLLIElement>) {
    event.preventDefault();
    event.stopPropagation();

    await runAsyncProcess(listingApi.bookmarkListing(listingProps.id));
  }

  function handleShareClick(event: React.MouseEvent<HTMLLIElement>) {
    event.preventDefault();
    event.stopPropagation();

    // TODO: Implement share
  }
}

export default ListingPropertyCardActionList;
