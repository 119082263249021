/* eslint-disable no-magic-numbers */
import {ReactComponent as DollarIcon} from "../../../core/ui/icon/dollar.svg";
import {ReactComponent as ExchangeIcon} from "../../../core/ui/icon/exchange.svg";
import {ReactComponent as PercentageIcon} from "../../../core/ui/icon/percentage.svg";

import "./_equity-share-label.scss";

import classNames from "classnames";

import ProgressBar from "../../progress-bar/ProgressBar";
import {formatPrice, formatNumber} from "../../../core/util/number/numberUtils";

type EquitySizeType = "large" | "medium" | "small" | "xsmall";
export interface EquityShareLabelProps {
  value: string | number;
  percentage: string | number;
  type?: "listed" | "available" | "sold" | "owned";
  title?: string;
  shouldDisplayProgressBar?: boolean;
  size?: EquitySizeType;
  isHidden?: boolean;
  customClassName?: string;
  shouldDisplayTitle?: boolean;
  fractionDigit?: number;
}

function EquityShareLabel({
  type,
  title = "",
  value,
  percentage,
  shouldDisplayProgressBar = true,
  customClassName,
  size = "medium",
  shouldDisplayTitle = true,
  // eslint-disable-next-line no-magic-numbers
  fractionDigit = 2
}: EquityShareLabelProps) {
  const equityShareLabelClassname = classNames(
    "equity-share-label",
    customClassName,
    `equity-share-label--size--${size}`,
    {
      "typography--paragraph": size === "medium",
      "typography--body": size === "small"
    }
  );

  const iconSize: number = generateIconSize();
  const exchangeIconSize = generateExchangeIconSize();
  const pStyleClassName = generatePStyleClassName();

  return (
    <div className={equityShareLabelClassname}>
      {shouldDisplayProgressBar && (
        <ProgressBar type={type} percentage={Number(percentage)} />
      )}

      <div className={"equity-share-label__value  typography--bold"}>
        <div className={"equity-share-label__icon"}>
          <DollarIcon width={iconSize} height={iconSize} />
        </div>
        <p className={pStyleClassName}>
          {formatPrice(
            Number(value),
            size === "small" ? 0 : undefined,
            0,
            size === "small" && !customClassName
          )}
        </p>
        <div className={"equity-share-label__exchange-icon"}>
          <ExchangeIcon width={exchangeIconSize.width} height={exchangeIconSize.height} />
        </div>

        <div className={"equity-share-label__icon"}>
          <PercentageIcon width={iconSize} height={iconSize} />
        </div>

        <p className={pStyleClassName}>{`${formatNumber({
          maximumFractionDigits: fractionDigit
        })(Number(percentage))}%`}</p>
      </div>
      {shouldDisplayTitle && (
        <p className={"equity-share-label__title typography--body"}>
          {generateEquityTitle()}
        </p>
      )}
    </div>
  );

  function generateEquityTitle() {
    let newTitle = title;

    if (!newTitle) {
      switch (type) {
        case "available":
          newTitle = "Equity Available";
          break;

        case "listed":
          newTitle = "Equity Listed";
          break;

        case "sold":
          newTitle = "Equity Sold";
          break;

        case "owned":
          newTitle = "Equity Owned";
          break;

        default:
          break;
      }
    }

    return newTitle;
  }

  function generateIconSize() {
    switch (size) {
      case "medium":
        return 18 as number;
      case "xsmall":
        return 10.3 as number;
      default:
        return 12 as number;
    }
  }

  function generateExchangeIconSize() {
    switch (size) {
      case "medium":
        return {width: 16, height: 9};
      case "xsmall":
        return {width: 10.3, height: 5.6};
      default:
        return {width: 14, height: 7};
    }
  }

  function generatePStyleClassName() {
    switch (size) {
      case "medium":
        return "typography--bold";
      case "xsmall":
        return "equity-share-label__value";
      default:
        return "typography--body--bold";
    }
  }
}

export default EquityShareLabel;
