import {DropdownOption} from "@hipo/react-ui-toolkit";
import {createContext, Dispatch} from "react";

import {FileWithBase64Value} from "../../../core/util/file/fileTypes";
import {PropertyListItem} from "../../api/listingApiModels";
import {
  CREATE_LISTING_FORM_TEXT_FIELD_NAMES,
  CREATE_LISTING_FROM_DROPDOWN_FIELD_NAMES,
  initialCreateListingFormState
} from "./form/createListingFormConstants";
import {CreateListingFormValidationObject} from "./form/createListingFormTypes";

export type CreateListingStep =
  | "property-details"
  | "listing-profile"
  | "inspection-appraisal"
  | "listing-creation"
  | "closing";

interface CreateListingState {
  step: CreateListingStep;
  formState: typeof initialCreateListingFormState;
  listing: PropertyListItem | null;
  listingCreationStepView:
    | "equity"
    | "property-information"
    | "property-dividend-upfront";
  listingCreationPropertyDetailStepView:
    | "property-basics"
    | "property-insurance"
    | "property-mortgage";
}

const initialCreateListingState: CreateListingState = {
  step: "property-details",
  formState: initialCreateListingFormState,
  listing: null,
  listingCreationStepView: "equity",
  listingCreationPropertyDetailStepView: "property-basics"
} as const;

type CreateListingStateReducerAction =
  | {
      type: "SET_STEP";
      payload: CreateListingStep;
    }
  | {
      type: "SET_INSURANCE_FILES";
      payload: File[];
    }
  | {
      type: "SET_MORTGAGE_FILES";
      payload: File[];
    }
  | {
      type: ValueOf<typeof CREATE_LISTING_FORM_TEXT_FIELD_NAMES>;
      payload: string;
    }
  | {
      type: ValueOf<typeof CREATE_LISTING_FROM_DROPDOWN_FIELD_NAMES>;
      payload: DropdownOption | null;
    }
  | {
      type: "SET_LISTING";
      payload: PropertyListItem;
    }
  | {type: "SET_INSPECTION_EXTRA_LINE_ITEM"; payload: "photography"}
  | {type: "REMOVE_LISTING_MEDIA"; payload: FileWithBase64Value}
  | {type: "ADD_LISTING_MEDIA"; payload: FileWithBase64Value[]}
  | {type: "ADD_INSURANCE_FILE"; payload: File[]}
  | {type: "ADD_MORTGAGE_FILE"; payload: File[]}
  | {type: "REMOVE_INSURANCE_FILE"; payload: File}
  | {type: "REMOVE_MORTGAGE_FILE"; payload: File}
  | {type: "CLEAR_LISTING_MEDIA"}
  | {type: "SET_VALIDATION_OBJECT"; payload: CreateListingFormValidationObject}
  | {
      type: "SET_LISTING_CREATION_STEP_VIEW";
      payload: "equity" | "property-information" | "property-dividend-upfront";
    }
  | {
      type: "SET_PROPERTY_DETAILS_STEP_VIEW";
      payload: "property-basics" | "property-insurance" | "property-mortgage";
    };

// eslint-disable-next-line complexity
function createListingStateReducer(
  state = initialCreateListingState,
  action: CreateListingStateReducerAction
) {
  let newState = state;

  switch (action.type) {
    case "SET_STEP": {
      newState = {
        ...state,
        step: action.payload
      };

      break;
    }

    case "SET_LISTING": {
      newState = {
        ...state,
        listing: action.payload,
        listingCreationStepView: action.payload.listed_equity_percentage
          ? "property-information"
          : "equity",
        formState: {
          ...state.formState,
          description: action.payload.property.description,
          address: action.payload.property.street_address,
          city: action.payload.property.city,
          zip: action.payload.property.postal_code,
          state: {
            id: action.payload.property.state,
            title: ""
          },
          insurance_files: action.payload.property.documents,
          // listing_media: action.payload.property.media,
          mortgage_files: action.payload.property.documents
          //   validationObject:
        }
      };
      break;
    }

    case "SET_INSURANCE_FILES":
      newState = {
        ...state,
        formState: {...state.formState, insurance_files: action.payload}
      };
      break;

    case "SET_MORTGAGE_FILES":
      newState = {
        ...state,
        formState: {...state.formState, mortgage_files: action.payload}
      };
      break;

    case "SET_INSPECTION_EXTRA_LINE_ITEM": {
      let newExtraLineItems = [...state.formState.inspection_extra_line_item_types];

      if (newExtraLineItems.includes(action.payload)) {
        newExtraLineItems = newExtraLineItems.filter((item) => item !== action.payload);
      } else {
        newExtraLineItems = [...newExtraLineItems, action.payload];
      }

      newState = {
        ...state,
        formState: {
          ...state.formState,
          inspection_extra_line_item_types: newExtraLineItems
        }
      };
      break;
    }

    case "REMOVE_LISTING_MEDIA": {
      newState = {
        ...state,
        formState: {
          ...state.formState,
          listing_media: state.formState.listing_media.filter(
            (file) => file !== action.payload
          )
        }
      };
      break;
    }

    case "ADD_LISTING_MEDIA":
      newState = {
        ...state,
        formState: {
          ...state.formState,
          listing_media: [...state.formState.listing_media, ...action.payload]
        }
      };
      break;

    case "ADD_INSURANCE_FILE":
      newState = {
        ...state,
        formState: {
          ...state.formState,
          insurance_files: [...state.formState.insurance_files, ...action.payload]
        }
      };
      break;

    case "ADD_MORTGAGE_FILE":
      newState = {
        ...state,
        formState: {
          ...state.formState,
          mortgage_files: [...state.formState.mortgage_files, ...action.payload]
        }
      };
      break;

    case "REMOVE_INSURANCE_FILE":
      newState = {
        ...state,
        formState: {
          ...state.formState,
          insurance_files: state.formState.insurance_files.filter(
            (file) => action.payload.name !== (file as File)?.name
          )
        }
      };
      break;

    case "REMOVE_MORTGAGE_FILE":
      newState = {
        ...state,
        formState: {
          ...state.formState,
          mortgage_files: state.formState.mortgage_files.filter(
            (file) => action.payload.name !== (file as File)?.name
          )
        }
      };
      break;

    case "CLEAR_LISTING_MEDIA":
      newState = {
        ...state,
        formState: {
          ...state.formState,
          listing_media: initialCreateListingFormState.listing_media
        }
      };
      break;

    case "SET_VALIDATION_OBJECT":
      newState = {
        ...state,
        formState: {...state.formState, validationObject: action.payload}
      };
      break;

    case "SET_LISTING_CREATION_STEP_VIEW":
      newState = {...state, listingCreationStepView: action.payload};
      break;

    case "SET_PROPERTY_DETAILS_STEP_VIEW":
      newState = {...state, listingCreationPropertyDetailStepView: action.payload};
      break;

    case CREATE_LISTING_FORM_TEXT_FIELD_NAMES.ADDRESS:
    case CREATE_LISTING_FORM_TEXT_FIELD_NAMES.CITY:
    case CREATE_LISTING_FORM_TEXT_FIELD_NAMES.ZIP:
    case CREATE_LISTING_FORM_TEXT_FIELD_NAMES.DESCRIPTION:
    case CREATE_LISTING_FROM_DROPDOWN_FIELD_NAMES.STATE: {
      newState = {
        ...state,
        formState: {
          ...state.formState,
          [action.type]: action.payload
        }
      };
      break;
    }

    default:
      break;
  }

  return newState;
}

const CreateListingContext = createContext({
  createListingState: initialCreateListingState,
  dispatchCreateListingStateAction: (() =>
    undefined) as Dispatch<CreateListingStateReducerAction>
});

export default CreateListingContext;
export {createListingStateReducer, initialCreateListingState};
