const MIN_ACCEPTED_AGE = 18;

const DATE_FORMAT = {
  /** example: 1 Jan 2021 */
  DAY_MONTH_YEAR_FORMAT: "d MMM yyyy",
  YEAR_MONTH_DAY_FORMAT: "yyyy-MM-dd",

  // example: 20 Nov, 04:15am
  DAY_MONTH_TIME_FORMAT: "dd MMM, hh:mmaaaaa'm'",

  // October 25, 2021 19:35:30 GMT
  DAY_MONTH_YEAR_TIME_FORMAT: "MMMM dd, yyyy HH:mm:ss 'GMT'",

  MONTH_YEAR_FORMAT: "MMM yyyy",

  YEAR_FORMAT: "yyyy",

  DAY_MONTH_YEAR_HOUR_FORMAT: "d-MM-yyyy, HH:mm a",

  MONTH_DAY_YEAR_FORMAT: "MMMM dd, yyyy"
};

// eslint-disable-next-line no-magic-numbers
const ONE_DAY = 1000 * 3600 * 24;
const MINS_IN_A_HOUR = 60;
const MONTHS_IN_A_YEAR = 12;

export {DATE_FORMAT, MIN_ACCEPTED_AGE, ONE_DAY, MINS_IN_A_HOUR, MONTHS_IN_A_YEAR};
