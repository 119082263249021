import "./_badge.scss";

import classNames from "classnames";

interface BadgeProps {
  children: React.ReactNode;
  type?: "monochrome" | "duetone";
  color?: "teal" | "gold" | "green" | "red";
  customClassName?: string;
}

function Badge({
  children,
  type = "monochrome",
  color = "teal",
  customClassName
}: BadgeProps) {
  const className = classNames(
    "badge",
    customClassName,
    `badge--color--${color}`,
    `badge--type--${type}`
  );

  return (
    <div className={className}>
      <div className={"badge__body"}>{children}</div>
    </div>
  );
}

export default Badge;
