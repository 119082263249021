import "./_app-layout.scss";

import classNames from "classnames";

import AppNavigation from "../navigation/AppNavigation";
import Footer from "../footer/Footer";

interface AppLayoutProps {
  children: React.ReactNode;
  customClassName?: string;
}

function AppLayout({children, customClassName}: AppLayoutProps) {
  return (
    <div className={classNames("app-layout", customClassName)}>
      <AppNavigation />

      <section className={"app-layout__content"}>{children}</section>

      <Footer />
    </div>
  );
}

export default AppLayout;
