import "./_progress-bar.scss";

import {
  ProgressBar as HipoProgressBar,
  ProgressBarProps as HipoProgressBarProps
} from "@hipo/react-ui-toolkit";
import classNames from "classnames";

type ProgressBarType =
  | "listed"
  | "available"
  | "sold"
  | "owned"
  | "mortgage"
  | "purchased";

type ProgressBarProps = Omit<HipoProgressBarProps, "style"> & {
  type?: ProgressBarType;
  style?: HipoProgressBarProps["style"];
};

function ProgressBar({type, style, ...otherProps}: ProgressBarProps) {
  return (
    <HipoProgressBar
      customClassName={classNames(`progress-bar--type--${type}`)}
      style={generateProgressBarStyle()}
      {...otherProps}
    />
  );

  function generateProgressBarStyle() {
    let progressBarStyle = style || {};

    switch (type) {
      case "sold":
        progressBarStyle = {
          trackColor: "var(--sunray)",
          backgroundColor: "rgba(var(--sunray-rgb),0.2)",
          completedColor: "var(--sunray)"
        };
        break;

      case "available":
        progressBarStyle = {
          trackColor: "var(--steel-teal)",
          backgroundColor: "rgba(var(--main-color-rgb),0.2)",
          completedColor: "var(--steel-teal)"
        };
        break;

      case "listed":
        progressBarStyle = {
          trackColor: "var(--steel-teal)",
          backgroundColor: "rgba(var(--steel-teal-rgb),0.2)",
          completedColor: "var(--steel-teal)"
        };
        break;
      case "owned":
        progressBarStyle = {
          trackColor: "var(--sunray)",
          backgroundColor: "rgba(var(--steel-teal-rgb),0.2)",
          completedColor: "var(--steel-teal)"
        };
        break;

      case "mortgage":
        progressBarStyle = {
          trackColor: "var(--steel-teal)",
          backgroundColor: "rgba(var(--steel-teal-rgb),0.2)",
          completedColor: "var(--steel-teal)"
        };
        break;

      case "purchased":
        progressBarStyle = {
          trackColor: "var(--steel-teal)",
          backgroundColor: "rgba(var(--steel-teal-rgb),0.2)",
          completedColor: "var(--copper-red)"
        };
        break;

      default:
        break;
    }

    return progressBarStyle;
  }
}

export default ProgressBar;
