import {DropdownOption} from "@hipo/react-ui-toolkit";

import {FileWithBase64Value} from "../../../../core/util/file/fileTypes";
import {PropertyDocument} from "../../../api/listingApiModels";
import {CreateListingFormValidationObject} from "./createListingFormTypes";

const CREATE_LISTING_FORM_TEXT_FIELD_NAMES = {
  ADDRESS: "address",
  CITY: "city",
  ZIP: "zip",
  DESCRIPTION: "description"
} as const;

const CREATE_LISTING_FROM_DROPDOWN_FIELD_NAMES = {
  STATE: "state"
} as const;

const initialCreateListingFormState = {
  [CREATE_LISTING_FORM_TEXT_FIELD_NAMES.ADDRESS]: "",
  [CREATE_LISTING_FORM_TEXT_FIELD_NAMES.CITY]: "",
  [CREATE_LISTING_FROM_DROPDOWN_FIELD_NAMES.STATE]: null as DropdownOption | null,
  [CREATE_LISTING_FORM_TEXT_FIELD_NAMES.ZIP]: "",
  [CREATE_LISTING_FORM_TEXT_FIELD_NAMES.DESCRIPTION]: "",

  insurance_files: [] as Array<File | PropertyDocument>,
  mortgage_files: [] as Array<File | PropertyDocument>,
  listing_media: [] as FileWithBase64Value[],

  validationObject: {} as CreateListingFormValidationObject,

  inspection_extra_line_item_types: ["appraisal-report", "photography"] as Array<
    "appraisal-report" | "photography"
  >
};

export {
  CREATE_LISTING_FORM_TEXT_FIELD_NAMES,
  initialCreateListingFormState,
  CREATE_LISTING_FROM_DROPDOWN_FIELD_NAMES
};
