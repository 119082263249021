import {createContext, Dispatch} from "react";

import {AuthUserProfileModel} from "../../../user/api/userApiModels";
import {WalletType} from "../../../wallet/utils/walletTypes";
import {
  resetWalletItemsOnWebStorage,
  saveWalletTypeToWebStorage
} from "../../util/storage/webStorageUtils";

interface AppState {
  currentUser: AuthUserProfileModel | null;
  walletType: WalletType | null;
}

const initialAppState: AppState = {
  currentUser: null,
  walletType: null as null | WalletType
} as const;

export type AppStateAction =
  | {
      type: "SET_CURRENT_USER";
      payload: {
        currentUser: AuthUserProfileModel | null;
      };
    }
  | {
      type: "SET_WALLET_TYPE";
      payload: {
        walletType: WalletType | null;
      };
    }
  | {
      type: "DISCONNECT";
    };

function appStateReducer(state = initialAppState, action: AppStateAction) {
  let newState = state;

  switch (action.type) {
    case "SET_CURRENT_USER": {
      newState = {
        ...state,
        ...action.payload
      };

      break;
    }

    case "SET_WALLET_TYPE": {
      newState = {
        ...state,
        ...action.payload
      };

      saveWalletTypeToWebStorage(action.payload.walletType);

      break;
    }

    case "DISCONNECT": {
      newState = {
        ...state,
        walletType: null
      };
      resetWalletItemsOnWebStorage();
      break;
    }

    default:
      break;
  }

  return newState;
}

const AppContext = createContext({
  appState: initialAppState,
  dispatchAppStateAction: (() => undefined) as Dispatch<AppStateAction>
});

export default AppContext;
export {appStateReducer, initialAppState};
