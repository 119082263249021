import {createContext, Dispatch} from "react";

import {ModalStackItem} from "../util/modalTypes";

interface ModalState {
  modalStack: ModalStackItem[];
}

const initialModalState: ModalState = {
  modalStack: []
};

type ModalStateAction =
  | {
      type: "OPEN_MODAL";
      payload: {
        item: ModalStackItem;
      };
    }
  | {
      type: "CLOSE_MODAL";
      payload: {
        id: string;
      };
    }
  | {
      type: "CLOSE_ALL_MODALS";
    };

function modalStateReducer(state = initialModalState, action: ModalStateAction) {
  let newState = state;

  switch (action.type) {
    case "OPEN_MODAL": {
      if (state.modalStack.find((item) => item.id === action.payload.item.id)) {
        throw new Error(`Modal ids should be unique`);
      }

      newState = {
        ...state,
        modalStack: [action.payload.item, ...state.modalStack]
      };

      break;
    }

    case "CLOSE_MODAL": {
      if (!state.modalStack.find((item) => item.id === action.payload.id)) {
        throw new Error(
          `Modal cannot be found: ${
            action.payload.id
          }. \nAvailable modal ids: ${state.modalStack.map((item) => item.id)}`
        );
      }

      newState = {
        ...state,
        modalStack: state.modalStack.filter((item) => item.id !== action.payload.id)
      };

      break;
    }

    case "CLOSE_ALL_MODALS": {
      newState = {
        ...state,
        modalStack: []
      };

      break;
    }

    default:
      break;
  }

  return newState;
}

const ModalContext = createContext({
  modalState: initialModalState,
  dispatchModalStateAction: (() => undefined) as Dispatch<ModalStateAction>
});

export default ModalContext;
export {initialModalState, modalStateReducer};
