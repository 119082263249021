import "./_listing-property-card-skeleton.scss";

import classNames from "classnames";

interface ListingPropertyCardSkeletonProps {
  size?: "small" | "large";
}

function ListingPropertyCardSkeleton({size = "small"}: ListingPropertyCardSkeletonProps) {
  return (
    <div
      className={classNames(
        "listing-property-card-skeleton",
        `listing-property-card-skeleton--size--${size}`
      )}
    >
      <div className={"listing-property-card-skeleton__carousel"} />
      <div className={"listing-property-card-skeleton__body"}>
        <div className={"listing-property-card-skeleton__price"} />
        <div className={"listing-property-card-skeleton__address"} />
        {renderDetailsSection()}
      </div>
    </div>
  );

  function renderDetailsSection() {
    // eslint-disable-next-line no-magic-numbers
    const detailWrapperCount = size === "large" ? 1 : 1;

    return Array.from({length: detailWrapperCount}).map((item, index) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={`listing-property-card-skeleton__details--${index}`}
        className={"listing-property-card-skeleton__details"}
      >
        {renderDetailItems()}
      </div>
    ));
  }

  function renderDetailItems() {
    // eslint-disable-next-line no-magic-numbers
    const detailItemCount = size === "large" ? 2 : 1;

    return Array.from({length: detailItemCount}).map((item, index) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={`listing-property-card-skeleton__details__item--${index}`}
        className={"listing-property-card-skeleton__details__item"}
      />
    ));
  }
}

export default ListingPropertyCardSkeleton;
