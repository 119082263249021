import {List, ListItem} from "@hipo/react-ui-toolkit";

import ListingPropertyCardSkeleton from "../../card/skeleton/ListingPropertyCardSkeleton";

const DEFAULT_PROPERTY_SKELETON_COUNT = 3;
const MIN_SKELETON_COUNT = 1;

interface PropertySkeletonListProps {
  size?: "large" | "small";
  customClassName?: string;
  count?: number;
}

function PropertySkeletonList({
  customClassName,
  size,
  count = DEFAULT_PROPERTY_SKELETON_COUNT
}: PropertySkeletonListProps) {
  return (
    <List
      customClassName={customClassName}
      items={Array.from({length: count || MIN_SKELETON_COUNT})}
    >
      {() => (
        <ListItem customClassName={"property-list__list-item"}>
          <ListingPropertyCardSkeleton size={size} />
        </ListItem>
      )}
    </List>
  );
}

export default PropertySkeletonList;
