import {ReactComponent as SmallLogo} from "../../../core/ui/assets/vesta-logo-small.svg";
import {ReactComponent as RegularLogo} from "../../../core/ui/assets/vesta-logo.svg";
import {ReactComponent as HamburgerIcon} from "../../../core/ui/icon/hamburger-menu.svg";
import {ReactComponent as CloseIcon} from "../../../core/ui/icon/close.svg";
import {ReactComponent as MobileLogo} from "../../../core/ui/icon/small-logo.svg";

import "./_app-navigation.scss";

import {Button, List, ListItem} from "@hipo/react-ui-toolkit";
import classNames from "classnames";
import {NavLink, matchPath} from "react-router-dom";
import {useState} from "react";

import {navigationItems} from "./navigationConstants";
import useAppContext from "../util/hook/useAppContext";

function AppNavigation() {
  const {
    appState: {currentUser}
  } = useAppContext();

  const [isMenuOpen, setMenuVisibility] = useState(false);

  const publicNavigationItems = navigationItems.filter(
    (item) => item.id === "marketplace"
  );

  return (
    <nav
      className={classNames("app-navigation", {
        "app-navigation--is-menu-open": isMenuOpen
      })}
    >
      <div className={"app-navigation__header"}>
        <div className={"app-navigation__logo"}>
          <a
            href={"https://vestaequity.net/"}
            target={"_blank"}
            rel={"noreferrer noopenner"}
          >
            <MobileLogo className={"app-navigation__logo--mobile"} />

            <SmallLogo className={"app-navigation__logo--small"} />
            <RegularLogo
              width={154}
              height={27}
              className={"app-navigation__logo--regular"}
            />
          </a>
        </div>

        {currentUser && (
          <Button
            onClick={handleToggleMenuVisibility}
            customClassName={"button--link app-navigation__menu-button"}
          >
            {renderMenuIcon()}
          </Button>
        )}
      </div>

      <List
        customClassName={"app-navigation__list"}
        items={currentUser ? navigationItems : publicNavigationItems}
      >
        {(item) => (
          <ListItem
            customClassName={classNames("app-navigation__list-item", {
              "app-navigation__list-item--is-active": matchPath(
                window.location.pathname,
                {
                  path: item.to,
                  exact: item.to === "/"
                }
              )
            })}
          >
            <NavLink
              className={"app-navigation__list-item__link"}
              to={item.to}
              exact={true}
            >
              {item.icon}

              <div className={"app-navigation__list-item__content"}>{item.content}</div>
            </NavLink>
          </ListItem>
        )}
      </List>
    </nav>
  );

  function renderMenuIcon() {
    if (isMenuOpen) {
      return (
        <CloseIcon
          width={"24px"}
          height={"24px"}
          className={
            "app-navigation__menu-button__icon app-navigation__menu-button__icon--close"
          }
        />
      );
    }

    return (
      <HamburgerIcon
        width={"24px"}
        height={"24px"}
        className={"app-navigation__menu-button__icon"}
      />
    );
  }

  function handleToggleMenuVisibility() {
    setMenuVisibility(!isMenuOpen);
  }
}

export default AppNavigation;
