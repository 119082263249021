import {Property, PropertyListItem} from "../api/listingApiModels";
import {calculatePercentage} from "../../core/util/number/numberUtils";
import {formatDate} from "../../core/util/date/dateUtils";

function isPropertyListItem(
  object: PropertyListItem | Property
): object is PropertyListItem {
  return object.hasOwnProperty("property");
}

function returnProperty(item: PropertyListItem | Property): Property {
  if (isPropertyListItem(item)) {
    return item.property;
  }
  return item;
}

/**
 * Returns the available most recent property value, priority-> adjusted_appraised_value,appraisal_report.property_value
 * @param {Property} item A Property object to extract property value
 * @return {string} The property value extracted from Property.
 */
function getPropertyValuation(item?: Property): string {
  let propertyValue = "0";

  if (item) {
    propertyValue = item.market_value;
  }

  return propertyValue;
}

function calculateTotalEquityListed(listings: PropertyListItem[]): number {
  return (
    listings
      ?.filter((listing) => listing.status !== "draft")
      .reduce(
        (total, listing) =>
          listing.property.appraisal_report?.property_value &&
          listing.listed_equity_percentage
            ? total +
              calculatePercentage(
                Number(listing.property.appraisal_report?.property_value),
                Number(listing.listed_equity_percentage)
              )
            : 0,
        0
      ) || 0
  );
}

/**
 * Returns an array of dates including current month's 1 date, 3 months from this day, 6 months from this day, 12,18,24,30,36..
 * @return {string[]} Array of dates in yyyy-MM-dd format including current mont and upcoming 3,6,12,18,24,30,36 months
 */
function generateForecastingMonths() {
  // eslint-disable-next-line no-magic-numbers
  const months = [0, 3, 6, 12, 18, 24, 30, 36];

  return months.map((month) => {
    const date = new Date();

    date.setMonth(date.getMonth() + month);
    date.setDate(1);

    return formatDate(date);
  });
}

export {
  isPropertyListItem,
  returnProperty,
  calculateTotalEquityListed,
  getPropertyValuation,
  generateForecastingMonths
};
