import {removeNullishValuesFromObject} from "../object/objectUtils";

/**
 * Initiates URLSearchParams with the provided params object, removes null values, and stringifies it
 * @param {object} params A params object.
 * @return {string} Stringified search string
 */
function stringifySearchParams<Params extends Record<string, any>>(params: Params) {
  return new URLSearchParams(removeNullishValuesFromObject(params)).toString();
}

/**
 * Converts query string into a params object (all values of the object are strings).
 * @param {string} search Url query string
 * @return {object} Query params object
 */
function getSearchParams<T = Record<string, undefined | string>>(search: string) {
  return Object.fromEntries(new URLSearchParams(search).entries()) as unknown as T;
}

/**
 * Generates query params string from the given value
 * @param {string} key query key
 * @param {string} value query value
 * @return {string}
 */
function generateQueryParamsString(queryParams: {[key: string]: string}) {
  const searchParams = new URLSearchParams(window.location.search);

  Object.keys(queryParams).forEach((key) => {
    if (queryParams[key]) {
      searchParams.set(key, queryParams[key]);
    } else {
      searchParams.delete(key);
    }
  });

  const queryParamsString = `?${searchParams.toString().replaceAll("%2C", ",")}`;

  return queryParamsString;
}

export {stringifySearchParams, getSearchParams, generateQueryParamsString};
