import "./_route-loading.scss";

import {Spinner} from "@hipo/react-ui-toolkit";

function RouteLoading() {
  return (
    <div className={"route-loading"}>
      <Spinner />
    </div>
  );
}

export default RouteLoading;
