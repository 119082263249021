import * as Sentry from "@sentry/browser";

import {isOnLocal} from "../../util/environment/environmentUtils";

interface SentryExceptionExtraData {
  title: string;
  data: any;
}

type SentryUserInfo = {
  id: string;
  email: string;
};

const isDevEnv = isOnLocal();

function initSentry() {
  if (!isDevEnv && process.env.REACT_APP_SENTRY_RELEASE) {
    const config: Sentry.BrowserOptions = {
      dsn: "https://332254aa58014cb6a5776cbefcff0acd@o118008.ingest.sentry.io/5991792",
      release: process.env.REACT_APP_SENTRY_RELEASE,
      environment: process.env.REACT_APP_BUILD_ENVIRONMENT,
      attachStacktrace: true,
      integrations: (integrations) => {
        // integrations will be all default integrations
        return [
          ...integrations.filter((integration) => integration.name !== "GlobalHandlers"),
          new Sentry.Integrations.GlobalHandlers({
            onerror: true,
            onunhandledrejection: false
          })
        ];
      }
    };

    Sentry.init(config);
  }
}

function sendSentryAnException(
  error: any,
  extra?: SentryExceptionExtraData,
  onCapture?: (eventId: string) => void
) {
  if (!isDevEnv) {
    Sentry.withScope((scope) => {
      if (extra) {
        scope.setExtras(extra as unknown as Record<string, unknown>);
      }

      const eventId = Sentry.captureException(error);

      if (onCapture) {
        onCapture(eventId);
      }
    });
  }
}

function showReportDialog(eventId: string) {
  if (!isDevEnv) {
    Sentry.showReportDialog({eventId});
  }
}

function setUserContextForSentry(userInfo: SentryUserInfo | null) {
  if (!isDevEnv) {
    Sentry.configureScope((scope) => {
      scope.setUser(userInfo);
    });
  }
}

export {initSentry, sendSentryAnException, showReportDialog, setUserContextForSentry};
