import {useCallback} from "react";
import {useHistory} from "react-router-dom";

import {generateQueryParamsString} from "../url/urlUtils";

/**
 * Hook that returns query parameters and query setter callback function
 * Pushes the new query param to the history
 * @returns {object} Query params and query setter callback function
 */
function useQueryParams<Params extends {[K in keyof Params]: string}>() {
  const history = useHistory();
  const setQueryParams = useCallback(
    (queryParams: Params) => {
      history.push({
        pathname: window.location.pathname,
        search: generateQueryParamsString(queryParams)
      });
    },
    [history]
  );

  const queryParams = Object.fromEntries(
    new URLSearchParams(window.location.search)
  ) as Params;

  return {queryParams, setQueryParams};
}

export default useQueryParams;
